import Toranto from "./Toranto.png";
import Ottawa from './ottawa.png';
import Burlington from './Burlington.png';
import Oakville from './Oakville.png';
import Hamilton from './Hamilton.png';
import Vaughan from './Vaughan.png';
import Kingston from "./Kingston.png";
import Markham from './Markham.png';
import London from './London.png'

export const Cursol = {
  Toranto: Toranto,
  Ottawa: Ottawa,
  Burlington: Burlington,
  Oakville: Oakville,
  Hamilton: Hamilton,
  Vaughan: Vaughan,
  Kingston: Kingston,
  Markham: Markham,
  London: London,
};