import React, { useState, useEffect, useRef } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import vehicleData from '../assets/vehicle/index'


const Vehicles = () => {
  const [currentVehicle, setCurrentVehicle] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const intervalRef = useRef(null);

  const prevVehicle = () => {
    clearInterval(intervalRef.current);
    setCurrentVehicle((prevIndex) => (prevIndex === 0 ? vehicleData.length - 1 : prevIndex - 1));
    resetInterval();
  };

  const nextVehicle = () => {
    clearInterval(intervalRef.current);
    setCurrentVehicle((prevIndex) => (prevIndex === vehicleData.length - 1 ? 0 : prevIndex + 1));
    resetInterval();
  };

  const resetInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentVehicle((prevIndex) => (prevIndex === vehicleData.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    resetInterval();

    return () => {
      clearInterval(intervalRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const MobileView = () => (
    <div className="grid grid-cols-8">
       <div className="opacity-40 col-span-1">
          <h3 className="rotate-90 translate-y-5 translate-x-[0.2%] sm:translate-y-9 font-medium text-2xl text-black Helvetica-regular">Vehicles</h3>
          <div className="rotate-90 translate-y-28 -translate-x-2 sm:translate-x-1 md:translate-x-3.5 lg:translate-x-7 xl:translate-x-10 2xl:translate-x-14 h-1 w-16 bg-black"></div>
        </div>
      <div className=" col-span-7 pl-4 pr-3 ">
        <h2 className="text-baseColor text-xl font-bold">
          Our Chauffeur-driven vip cars
        </h2>
        <div className="relative overflow-hidden">
          {/* <div className="absolute top-2 left-8 bg-baseColor h-0.5 w-24 mt-14 rotate-90"></div> */}
          <div className="flex items-center gap-9 mt-4">
            <div className="flex">
              <BiChevronLeft
                className="w-8 h-8 opacity-40 cursor-pointer"
                onClick={prevVehicle}
              />
              <BiChevronRight
                className="w-8 h-8 opacity-40 cursor-pointer"
                onClick={nextVehicle}
              />
            </div>
            <h2 className="text-lg font-medium">
              {vehicleData[currentVehicle].type}
            </h2>
          </div>
          <div className="w-92 h-80 my-5 flex-none relative">
            <img
              className="w-full h-full object-contain absolute inset-0 mix-blend-color-burn"
              src={vehicleData[currentVehicle].image}
              alt={vehicleData[currentVehicle].name}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#F5F5F5] py-7 col-span-full Helvetica-regular">
        <div className=" space-y-5 max-w-[83%] ml-auto sm:mx-auto sm:max-w-[70%]">
          <p className="text-sm font-medium">
            We provide all kinds of luxury cars for your
          </p>
          <p className="opacity-60 text-lg md:tracking-wider space-y-4">
            Providing seamless and sophisticated airport transfers, we ensure
            that every journey to and from the airport is a pinnacle of comfort
            and efficiency, reflecting our commitment to exceptional service.
          </p>
          <p className="opacity-60 text-lg md:tracking-wider space-y-4">
            Providing seamless and sophisticated airport transfers, we ensure
            that every journey to and from the airport is a pinnacle of comfort
            and efficiency, reflecting our commitment to exceptional service.
          </p>
        </div>
      </div>
    </div>
  );

  const DesktopView = () => (
    <div className="grid grid-cols-2">
      <div className="grid grid-cols-12 py-14 space-y-4 bg-grayLight col-span-1 md:col-span-1">
      <div className="opacity-40 col-span-2 lg:col-start-2 flex flex-col">
          <h3 className="rotate-90 translate-y-10 lg:translate-y-12 font-medium text-2xl text-black Helvetica-regular">Vehicles</h3>
          <div className="rotate-90 translate-y-36 lg:translate-y-32 lg:translate-x-3 xl:translate-x-5 2xl:translate-x-7 h-1 w-16 bg-black"></div>
        </div>
         
        <div className="space-y-5 col-span-10 lg:col-start-4 lg:col-end-12">
          <h2 className="text-baseColor text-2xl font-bold">
            Our Chauffeur-driven vip cars
          </h2>
          <p className="text-base font-semibold">
            We provide all kinds of luxury cars for your
          </p>
          <div className="opacity-60 Helvetica-regular text-sm lg:text-lg pr-9 space-y-4 w-full">
            <p>
              Experience the ultimate in luxury and comfort with Air Canada
              Limo's chauffeur-driven VIP cars. Our fleet of high-end vehicles
              is designed to cater to your every need, offering a seamless
              travel experience across Ontario. With professional chauffeurs at
              your service, you can enjoy a stress-free journey, whether for
              business or leisure.
            </p>
            <p>
              At Air Canada Limo, we provide a wide selection of luxury cars to
              suit any occasion. From sleek sedans to spacious SUVs, our diverse
              fleet ensures you find the perfect vehicle for your needs. Enjoy
              the convenience and elegance of our premium cars, available for
              airport transfers, corporate events, and special occasions.
            </p>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col justify-center pt-12 pb-10">
        {/* <div className="absolute top-24 left-20 bg-baseColor h-0.5 w-48 z-10 rotate-90"></div> */}
        <div className="max-w-[488.5px] 2xl:max-w-xl">
          <div className="flex items-center justify-between gap-12">
            <div>
              <h2 className="text-base font-semibold">
                {vehicleData[currentVehicle].type}
              </h2>
              <p className="text-xs">{vehicleData[currentVehicle].name}</p>
            </div>
            <div className="flex items-center md:mr-5">
              <BiChevronLeft
                className="size-10 opacity-40 cursor-pointer"
                onClick={prevVehicle}
              />
              <BiChevronRight
                className="size-10 opacity-40 cursor-pointer"
                onClick={nextVehicle}
              />
            </div>
          </div>
          <div className="h-80 md:w-11/12 w-full mt-3">
            <img
              className="w-full h-full bg-center bg-cover"
              src={vehicleData[currentVehicle].image}
              alt={vehicleData[currentVehicle].name}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  );
};

export default Vehicles;
