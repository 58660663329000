import React from "react";

export function Footer() {
  return (
    <footer className="bg-black">
      <div className="lg:max-w-[90%] xl:max-w-[87%] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16 gap-8 pt-10 px-[6.7%] sm:px-[5%] lg:px-[4%] xl:px-[2%] ">
          <div className="space-y-8 text-white">
            <div className="space-y-3">
              <h2 className="text-5xl text-baseColor homenaje-regular">
                Air Canada Limo
              </h2>
              <p className="text-2xl lg:text-lg xl:text-2xl border-b-2 border-white pb-6">
                We provide the best chauffeur service in town
              </p>
            </div>
            <div className="flex space-x-6">
              <a href="#t" className="text-gray-900 hover:text-gray-900">
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-10 w-10 bg-white rounded-md p-1"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a className="text-gray-900 hover:text-gray-900">
                <span className="sr-only">Email</span>
                <svg
                  className="h-10 w-10 rounded-md bg-white p-1"
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
                </svg>
              </a>
              <a className="text-gray-900 hover:text-gray-900">
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-10 w-10 rounded-md bg-white p-1"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className=" mb-6 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 lg:gap-3 gap-8 text-white">
            <div>
              <h3 className="text-2xl text-white">
                Vehicles
              </h3>
              <ul className="mt-3 space-y-1.5">
                <li>
                  <a className="text-lg">Luxury Suv</a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Luxury Sedan
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Luxury Van
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl text-white">
                Top cities
              </h3>
              <ul className="mt-3 space-y-1.5">
                <li>
                  <a className="text-lg">Toronto</a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Ottawa
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Burlington
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Oakville
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Hamilton
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Guelph
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Kingston
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Londonn
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Markham
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl text-white">
                Explore
              </h3>
              <ul className="mt-3 space-y-1.5">
                <li>
                  <a className="text-lg">City-to-city rides</a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Limousine service
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Chauffeur service
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Private car service
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Ground Transportation
                  </a>
                </li>
                <li>
                  <a href="#t" className="text-lg">
                    Airport Transfer
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="py-8 px-4 border-t border-white md:pl-5">
          <p className="text-lg text-white">
            &copy; All rights reserved to Air Canada Limo
          </p>
        </div>
      </div>
    </footer>
  );
}
