import { useEffect, useState } from "react";
import "./App.css";
import Booking from "./app/pages/booking";
import { useForm, useWatch } from "react-hook-form";
import ChauffeurService from "./app/main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./app/pages/error";
import ConfirmationPage from "./app/pages/confirmationPage";
import axios from "axios";
import { method_types } from "./components/common.lib";

function App() {
  const [booking, setBooking] = useState(false);
  const { ...formHandler } = useForm();

  /// setup routes for the app '/booking' and ('/chauffeur-service' or '/')
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ChauffeurService
              setBooking={setBooking}
              formHandler={formHandler}
            />
          }
        />
        <Route
          path="/chauffeur-service"
          element={
            <ChauffeurService
              setBooking={setBooking}
              formHandler={formHandler}
            />
          }
        />
        <Route
          path="/booking"
          element={
            <Booking
              formHandler={formHandler}
              booking={booking}
            />
          }
        />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/confirm" element={<ConfirmationPage formHandler={formHandler} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
