import React, { useEffect } from "react";
import { HiOutlineUser } from "react-icons/hi2";
import { meta } from "../../../../components/common.lib";
import Bag from "../../../../assets/icons/bag.png";
function Vehicle({ formHandler, vehicleError, setVehicleError }) {
  //  useEffect(() => {
  //       // empty vehicle type when the component is mounted
  //       // formHandler.setValue("trip_details.vehicle_type", "");
  //  }, []);

  return (
    <div className="mt-6">
      <h2 className="text-2xl font-semibold">Select Vehicle:</h2>
      {vehicleError && (
        <p className="text-red-500 text-sm font-semibold mt-2">
          Please select a vehicle to proceed
        </p>
      )}
      {meta.vehicle_type?.map((vehicle, index) => {
        let checkLimit = false;
        if (
          formHandler.getValues()?.trip_details?.number_of_bags == "4-7" ||
          formHandler.getValues()?.trip_details?.number_of_passengers == "4-7"
        ) {
          checkLimit = true;
        }
        return (
          <RadioButton
            key={index}
            vehicle={vehicle}
            formHandler={formHandler}
            setVehicleError={setVehicleError}
            disabled={checkLimit && vehicle.limit == "1-3"}
          />
        );
        åß;
      })}
    </div>
  );
}

export default Vehicle;

const RadioButton = ({ formHandler, vehicle, setVehicleError, disabled }) => {
  const { register } = formHandler;
  return (
    <div
      class={`flex items-center justify-between gap-x-3 px-5 py-6 bg-grayLight rounded-lg mt-7 ${
        disabled && "cursor-not-allowed bg-gray-200"
      }`}
      onClick={() => {
        if (!disabled) {
          formHandler.setValue(
            "trip_details.vehicle_type",
            JSON.stringify(vehicle)
          );
        }
      }}
    >
      <div className="flex items-center gap-5">
        <input
          type="radio"
          class={`before:content[''] peer relative h-4 w-4 accent-black ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          }border-gray-300 text-gray-600 focus:ring-gray-600 `}
          id="black"
          // register in react hook form
          {...register("trip_details.vehicle_type")}
          value={JSON.stringify(vehicle)}
          onChange={() => setVehicleError(false)}
          disabled={disabled}
        />
        <div className="flex items-center gap-1.5">
          <img src={vehicle.icon} className="w-10 h-8" />
          <div>
            <h2 class="font-semibold tracking-wide text-gray-800">
              {vehicle.label}
            </h2>
            <div className="flex items-center gap-1.5">
              <div className="flex items-center gap-1.5">
                <HiOutlineUser className="size-3" />
                <p className="text-sm font-medium tracking-wide text-gray-600 dark:text-gray-400">
                  {vehicle.passenger_space}
                </p>
              </div>
              <div className="flex items-center gap-1.5">
                <img src={Bag} className="size-3" />
                <p className="text-sm font-medium tracking-wide text-gray-600 dark:text-gray-400">
                  {vehicle.bag_space}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="mt-1 text-base font-semibold tracking-wide text-black">
        $ {vehicle.price}
      </p>
    </div>
  );
};
