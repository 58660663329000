const questions = [
    {
      id: 1,
      title: 'What services does Air Canada Limo provide?',
      info:'Air Canada Limo provides: Airport Transfers, Corporate Transportation, Special Occasion Transportation, Personal Transportation, Group Transportation, City Tours, Long Distance Travel, VIP services, Event Transportation, Medical Transportation.',
    },
    {
      id: 2,
      title: 'In how many countries Air Canada Limo operates?',
      info:'We mainly operates in Canada.',
    },
    {
      id: 3,
      title: 'Does Air Canada Limo have the best limousine fleet?',
      info:'EWe try to provide best and luxury limousine fleet. Our fleet includes: Luxury Sedan(i.e. Mercedes or similar), Premium Sedan(Tesla), Luxury SUV(Chevrolet Suburban or similar), Cadillac Escalade.',
    },
    {
      id: 4,
      title: 'Is booking limoservice with Air Canada Limo easy?',
      info:
        'Yes, With easy to navigate interface you can easily book a limoservice just in minutes.',
    },
    {
      id: 5,
      title: 'Why should I pre-book a limousine service?',
      info:'Pre-booking a limousine service ensures guaranteed availability and punctuality, providing a stress-free and luxurious travel experience. It allows for better planning and customization to meet your specific needs.',
    },
    {
      id: 6,
      title: 'How do I know that my booking is confirmed',
      info:'You will get a confirmation call and email from our customer service team shortly after you book.',
    },
    {
      id: 7,
      title: 'How can I recognize the driver?',
      info:"You will receive your driver's contact number in advance, allowing for easy communication and coordination upon arrival.",
    },
    {
      id: 8,
      title: 'How to get the best limousine driver in Ontario',
      info:"To get the best limousine driver in Ontario, choose Air Canada Limo. Our chauffeurs are highly trained professionals dedicated to providing exceptional service, ensuring a safe, comfortable, and luxurious travel experience tailored to your needs.",
    },
    {
      id: 9,
      title: 'What happens if I arrive late or my flight is delayed?',
      info:"If you arrive late or your flight is delayed, Air Canada Limo monitors real-time flight status to adjust your pick-up time accordingly. Our chauffeurs will be there when you arrive, ensuring a seamless and stress-free experience.",
    },
  ]
  export default questions