import TripDetails from "./TripDetailsForm";
import Vehicle from "./Vehicle";
import ConfirmationDetails from "./ConfirmationDetails";
import { Stripe } from "./stripe";

export const Screens = {
    ConfirmationDetails,
    TripDetails,
    Vehicle,
    Stripe
}