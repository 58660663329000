import tesla from './Tesla.jpg'
import motor_coach_2 from './motor-coach-2-768x471.webp'
import mini_buss from './mini-bus-768x471.webp'
import mercedes_s_class from './Mercedes S class.jpeg';
import cadillac_escalade from './cadillac-escalade-768x470.webp';
import cadillac_cts from './cadillac-cts-768x471.webp';
import chevy_suburban from './chevy-suburban-768x471.webp'


const vehicleData = [
    {
        name: 'Tesla',
        image: tesla,
        type: 'Sedan'
    },
    {
        name: 'Motor Coach',
        image: motor_coach_2,
        type: ''
    },
    {
        name: 'Mini Bus',
        image: mini_buss,
        type: ''
    },
    {
        name: 'Mercedes S Class',
        image: mercedes_s_class,
        type: 'Sedan'
    },
    {
        name: 'Cadillac Escalade',
        image: cadillac_escalade,
        type: 'SUV'
    },
    {
        name: 'Cadillac CTS',
        image: cadillac_cts,
        type: 'Sedan'
    },
    {
        name: 'Chevy Suburban',
        image: chevy_suburban,
        type: 'SUV'
    }
]

export default vehicleData;