import React from "react";
import logo from "../../assets/images/car-images.jpeg";
const About = () => {
  return (

    <section class="flex flex-col lg:flex-row justify-center lg:ml-12 xl:ml-20 lg:-space-x-16 mb-10">

      <div class="mix-blend-multiply flex rounded-full order-2 lg:order-1 md:w-[96%] lg:w-[700px] xl:w-[650px] 2xl:w-[72%] ">
        <div className="opacity-40 mt-6">
          <h3 className="rotate-90 font-medium text-2xl text-black Helvetica-regular">About</h3>
          <div className="rotate-90 h-1 w-16 bg-black mt-20 sm:-ml-0.5 mx-auto"></div>
        </div>
        <div className="Helvetica-regular opacity-70 pb-10">
          <h3 className="text-baseColor text-2xl sm:text-2xl lg:text-2xl xl:text-3xl lg:tracking-wide font-bold">
            WE PROMISE, YOU WILL HAVE THE BEST EXPERIENCE
          </h3>
          <div className="space-y-2 mt-3 text-lg md:text-2xl">
            <p>
              Welcome to Air Canada Limo, your premier choice for chauffeur and
              livery services in Ontario. Since our establishment, we have
              dedicated ourselves to offering unparalleled luxury transportation
              solutions, setting a new standard in the industry. Our commitment
              to providing exceptional service at competitive rates has made us
              a preferred alternative to other service providers, including our
              main competitor, Rosedale Livery.
            </p>
            <p>
              At Air Canada Limo, we understand the importance of reliable and
              luxurious transportation. Whether you need airport transfers,
              corporate transportation, or chauffeur services for special
              occasions, our fleet of premium vehicles and professional
              chauffeurs ensure a seamless and comfortable journey. We pride
              ourselves on delivering top-notch services tailored to meet the
              unique needs of each client.
            </p>
            <p>
              Air Canada Limo is dedicated to setting the benchmark in luxury
              transportation by offering reliable, efficient, and affordable
              chauffeur and livery services. Our mission is to enhance the
              travel experience of our clients through exceptional service,
              professionalism, and attention to detail.
            </p>
          </div>
        </div>
      </div>
      <div class="mix-blend-multiply rounded-full mx-4 lg:mx-0 lg:order-2 lg:w-[80%] xl:w-[58%] 2xl:w-full">
        <img
          src={logo}
          alt=""
          className="opacity-50 h-5/6 lg:h-full w-full"
        />
      </div>
    </section>
  );
};

export default About;
