import React from "react";
import { BsAirplane } from "react-icons/bs";
import { FaAccessibleIcon } from "react-icons/fa";
import { LuClock11, LuUsers2 } from "react-icons/lu";

const Services = () => {
  return (
    <main className="my-10">
      <section className="grid grid-cols-12">
        <div className="opacity-40 col-span-2 lg:col-span-1 lg:col-start-2 flex flex-col">
          <h3 className="rotate-90 origin-top-left translate-x-12 lg:translate-x-3 xl:translate-x-5 font-medium text-2xl text-black Helvetica-regular">
            Services
          </h3>
          <div className="rotate-90 origin-top-left translate-x-8 lg:-translate-x-1 xl:translate-x-1 translate-y-20 h-1 w-16 bg-black"></div>
        </div>
        <div className="col-span-10 lg:col-start-3 lg:col-end-12">
          <h2 className="text-baseColor text-2xl lg:text-3xl font-bold mb-3">
            Providing amazing services to our clients.
          </h2>
          <div className="text-center md:grid-cols-2 lg:grid-cols-2 grid gap-3 mt-10 m-2 items-start">
            <div className="grid grid-cols-1 gap-5 mt-3">
              <div className="mx-auto border-dashed border-2 rounded-full p-6">
                <BsAirplane className="size-12 rotate-45 opacity-60" />
              </div>
              <div className="space-y-3">
                <h2 className="font-medium text-2xl">Airport Transfer</h2>
                <div className="w-14 h-1 bg-baseColor mt-1 mx-auto"></div>
              </div>
              <p className="text-lg opacity-60 text-center sm:w-11/12 lg:w-full">
                Experience seamless and reliable airport transfer services. Our
                professional chauffeurs ensure timely arrivals and departures,
                providing stress-free travel to and from the airport.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-5 mt-3">
              <div className="mx-auto border-dashed border-2 rounded-full p-6">
                <LuClock11 className="size-12 opacity-60" />
              </div>
              <div className="space-y-3">
                <h2 className="text-2xl font-medium">Road Shows</h2>
                <div className="w-14 h-1 bg-baseColor mt-1 mx-auto"></div>
              </div>
              <p className="text-lg opacity-60 text-center sm:w-11/12 lg:w-full">
                We specialize in expertly coordinated road shows across Ontario,
                ensuring each event runs smoothly and on schedule. Our dedicated
                team and professional chauffeurs deliver precise, reliable
                service, making your road show a success from start to finish.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 mt-3">
              <div className="mx-auto border-dashed border-2 rounded-full p-6">
                <LuUsers2 className="size-12 opacity-60" />
              </div>
              <div className="space-y-3">
                <h2 className="text-2xl font-medium">Corporate Groups</h2>
                <div className="w-14 h-1 bg-baseColor mt-1 mx-auto"></div>
              </div>
              <p className="text-lg opacity-60 text-center sm:w-11/12 lg:w-full">
                We offers tailored corporate group transportation, ensuring your
                team arrives together and on time. Our spacious and comfortable
                vehicles are perfect for meetings, conferences, and corporate
                events, providing a seamless and professional travel experience
                for your business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 mt-3">
              <div className="mx-auto border-dashed border-2 rounded-full p-6">
                <FaAccessibleIcon className="size-12 opacity-60" />
              </div>
              <div className="space-y-3">
                <h2 className="font-medium text-2xl">Accessible</h2>
                <div className="w-14 h-1 bg-baseColor mt-1 mx-auto"></div>
              </div>
              <p className="text-lg opacity-60 text-center sm:w-11/12 lg:w-full">
                Providing reliable and comfortable accessible transportation
                services, ensuring safe travel for passengers with mobility
                challenges. Our vehicles are equipped with the necessary
                features to accommodate all accessibility needs, offering a
                smooth and stress-free ride for everyone.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Services;
