import React from "react";
import { useForm } from "react-hook-form";
import { InputField } from "../../../../components/ReactHookFields/input";
import { MaskField } from "../../../../components/ReactHookFields/MuskField";
import { ReactSelectField } from "../../../../components/ReactHookFields/SelectField";
import { Textarea } from "../../../../components/ReactHookFields/TextArea";
import { getPrice, meta } from "../../../../components/common.lib";

function TripDetails({ formHandler }) {
  // const onSubmit = (data) => {
  //      getPrice({method, ...data});
  //      setActiveTab("select-vehicle");
  // };

  return (
    <form className="mt-5 md:mt-10 lg:mt-10 xl:mt-10">
      <p className="font-medium text-2xl my-5">Passenger’s Details:</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5">
        <InputField
          title="First Name"
          name="passenger.first_name"
          placeholder="First Name"
          control={formHandler.control}
          rules={{
            required: {
              value: true,
              message: "First Name is required",
            },
          }}
          errors={formHandler.formState.errors}
        />
        <InputField
          title="Last Name"
          name="passenger.last_name"
          placeholder="Last Name"
          control={formHandler.control}
          errors={formHandler.formState.errors}
          rules={{
            required: {
              value: true,
              message: "Last Name is required",
            },
          }}
        />
        <InputField
          title="Email"
          name="passenger.email"
          placeholder="Email"
          errors={formHandler.formState.errors}
          control={formHandler.control}
          type="email"
          rules={{
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format"
            }
          }}
        />
        <MaskField
          title="Phone Number"
          name="passenger.phone_number"
          placeholder="Phone Number"
          control={formHandler.control}
          errors={formHandler.formState.errors}
          mask="99999999999"
          rules={{
            required: {
              value: true,
              message: "Phone Number is required",
            },
          }}
        />
      </div>

      <div className="mt-10">
        <p className="font-medium text-2xl my-5">Trip Details:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5">
          <ReactSelectField
            title="Number Of Passengers"
            name="trip_details.number_of_passengers"
            placeholder="Select"
            errors={formHandler.formState.errors}
            control={formHandler.control}
            optionData={meta.number_of_passengers}
            uniqueKey="value"
            onChange={(e) =>{
              formHandler.setValue("trip_details.number_of_passengers", e.value);
              formHandler.setValue("trip_details.vehicle_type", '')
              }
            }
            rules={{
              required: {
                value: true,
                message: "Number Of Passengers is required",
              },
            }}
          />
          <ReactSelectField
            title="Number of Bags"
            name="trip_details.number_of_bags"
            placeholder="Select"
            errors={formHandler.formState.errors}
            control={formHandler.control}
            uniqueKey="value"
            optionData={meta.number_of_bags}
            onChange={(e) =>{
              formHandler.setValue("trip_details.number_of_bags", e.value);
              formHandler.setValue("trip_details.vehicle_type", '');
            }}
            rules={{
              required: {
                value: true,
                message: "Number Of Bags is required",
              },
            }}
          />
          <ReactSelectField
            title="Baby Seat, Wheel Chair - $10/seat"
            name="trip_details.baby_seat_wheel_chair"
            placeholder="Select"
            control={formHandler.control}
            optionData={meta.baby_seat_wheel_chair}
            uniqueKey="value"
            isMulti={true}
            onChange={(e) =>
              formHandler.setValue(
                "trip_details.baby_seat_wheel_chair",
                e.map((item) => item.value)
              )
            }
          />
          <ReactSelectField
            title="Pet Seat - $15/seat"
            name="trip_details.pet_seat"
            placeholder="Select"
            control={formHandler.control}
            uniqueKey="value"
            optionData={meta.pet_seat}
            onChange={(e) =>
              formHandler.setValue("trip_details.pet_seat", e.value)
            }
          />
          <InputField
            title="Extra Stop - $15/stop"
            name="trip_details.extra_stop"
            placeholder="Please add number of stops"
            control={formHandler.control}
          />
          <ReactSelectField
            title="Meet & Greet Services - $60/Sedan, $75/Suv"
            name="trip_details.meet_greet_services"
            placeholder="Select"
            control={formHandler.control}
            uniqueKey="value"
            optionData={meta.meet_greet_services}
            onChange={(e) =>
              formHandler.setValue("trip_details.meet_greet_services", e.value)
            }
          />
          <div className="md:col-span-2 lg:col-span-2 xl:col-span-2">
            <InputField
              title="Flight Number"
              name="trip_details.flight_number"
              placeholder="ACA-406, Terminal 1, 2, 3."
              control={formHandler.control}
            />
            <span className="text-gray-400 text-lg">
            If airport arrival is selected $15 for airport permit will be included at the checkout.
            </span>
          </div>
          <div className="md:col-span-2 lg:col-span-2 xl:col-span-2">
            <Textarea
              title="Additional Information"
              name="trip_details.additional_information"
              placeholder="Add stops with location names i.e., CN Tower, Hotel, etc. & Special Instructions."
              control={formHandler.control}
              rows={4}
            />
          </div>
        </div>
        {/* next button */}

        {/* <button className="bg-[#007AFF] text-white py-2 px-4 rounded-lg w-full" type="submit" onClick={formHandler.handleSubmit(onSubmit)}>
                    Next
               </button> */}
      </div>
    </form>
  );
}

export default TripDetails;
