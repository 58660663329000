import emailjs from "emailjs-com";
import { cloneDeep } from "lodash";
import premium_sedan from "../assets/icons/premium_sedan.png";
import luxury_sedan from "../assets/icons/luxury_sedan.png";
import luxury_suv from "../assets/icons/luxury_suv.png";
import cadillac_escalade from "../assets/icons/cadillac_escalade.png";

export function sendEmail(providedDetails) {
  emailjs
    .send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      providedDetails?.template_id,
      {
        to_name: providedDetails?.name,
        to_email: providedDetails?.email,
        ...providedDetails,
      },
      process.env.REACT_APP_EMAIL_USER_ID
    )
    .then(
      () => {
        console.log("SUCCESS!");
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
}

export const meta = {
  number_of_passengers: [
    { value: "1-3", label: "1-3" },
    { value: "4-7", label: "4-7" },
  ],
  number_of_bags: [
    { value: "1-3", label: "1-3" },
    { value: "4-7", label: "4-7" },
  ],
  baby_seat_wheel_chair: [
    { value: "Infant (ages 0-1)", label: "Infant (ages 0-1)" },
    { value: "Toddler seat (ages 1-3)", label: "Toddler seat (ages 1-3)" },
    { value: "Booster seat (ages 3-6)", label: "Booster seat (ages 3-6)" },
    { value: "Wheel chair", label: "Wheel chair" },
  ],
  pet_seat: [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ],
  meet_greet_services: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  duration: [
    { value: "3", label: "3 hours" },
    { value: "4", label: "4 hours" },
    { value: "5", label: "5 hours" },
    { value: "6", label: "6 hours" },
    { value: "7", label: "7 hours" },
    { value: "8", label: "8 hours" },
    { value: "9", label: "9 hours" },
    { value: "10", label: "10 hours" },
    { value: "11", label: "11 hours" },
    { value: "12", label: "12 hours" },
    { value: "13", label: "13 hours" },
    { value: "14", label: "14 hours" },
    { value: "15", label: "15 hours" },
    { value: "16", label: "16 hours" },
    { value: "17", label: "17 hours" },
    { value: "18", label: "18 hours" },
    { value: "19", label: "19 hours" },
    { value: "20", label: "20 hours" },
    { value: "21", label: "21 hours" },
    { value: "22", label: "22 hours" },
    { value: "23", label: "23 hours" },
    { value: "24", label: "24 hours" },
  ],

  vehicle_type: [
    {
      type: "luxury_sedan",
      price: 90,
      meet_greet_chrg: 60,
      label: "Luxury Sedan",
      bag_space: 3,
      vehicle_type: "sedan",
      passenger_space: 3,
      icon: luxury_sedan,
      limit: "1-3",
    },
    {
      type: "premium_sedan",
      price: 90,
      meet_greet_chrg: 60,
      label: "Premium Sedan",
      bag_space: 3,
      vehicle_type: "sedan",
      passenger_space: 3,
      icon: premium_sedan,
      limit: "1-3",
    },
    {
      type: "luxury_suv",
      price: 120,
      meet_greet_chrg: 75,
      label: "Luxury Suv",
      bag_space: 7,
      vehicle_type: "suv",
      passenger_space: 7,
      icon: luxury_suv,
      limit: "4-7",
    },
    {
      type: "cadillac_esclade",
      price: 125,
      meet_greet_chrg: 75,
      label: "Cadillac Esclade (SUV)",
      bag_space: 7,
      vehicle_type: "suv",
      passenger_space: 7,
      icon: cadillac_escalade,
      limit: "4-7",
    },
  ],
};

export const getDistance = (distance) => {
  let final_distance;
  // Check if the distance is in km or miles
  if (distance?.toLowerCase()?.includes("km")) {
    final_distance = parseFloat(distance); // Assuming distance is in "X km" format
  } else if (distance?.toLowerCase()?.includes("miles")) {
    let miles = parseFloat(distance); // Assuming distance is in "X miles" format
    final_distance = miles * 1.60934; // Convert miles to kilometers
  }
  return final_distance;
};

export const getPrice = (values) => {
  let bookingDetails = cloneDeep(values?.booking_details);
  let TripDetails = cloneDeep(values?.trip_details);
  let price = 0;
  let additional_price = 0;

  if (values?.method == "one_way") {
    let distance = getDistance(values?.booking_details?.distance);
    if (distance && distance > 30) {
      let remaining_distance = distance - 30;
      if (TripDetails?.vehicle_type &&JSON.parse(TripDetails?.vehicle_type)?.vehicle_type == "sedan") {
        price += remaining_distance * 2;
      }
      if (TripDetails?.vehicle_type && JSON.parse(TripDetails?.vehicle_type)?.vehicle_type == "suv") {
        price += remaining_distance * 3;
      }
    }
  }

  if (values?.method == "by_the_hour") {
    let duration = Number(bookingDetails?.duration);
    price += duration * 10;
  }

  if (TripDetails?.baby_seat_wheel_chair) {
    price += TripDetails?.baby_seat_wheel_chair.length * 10;
    additional_price += TripDetails?.baby_seat_wheel_chair.length * 10;
  }

  if (TripDetails?.pet_seat) {
    price += Number(TripDetails?.pet_seat) * 15;
    additional_price += Number(TripDetails?.pet_seat) * 15;
  }

  if (TripDetails?.extra_stop) {
    price += Number(TripDetails?.extra_stop) * 15;
    additional_price += Number(TripDetails?.extra_stop) * 15;
  }

  if (TripDetails?.meet_greet_services == "Yes") {
    if (TripDetails?.vehicle_type) {
      price += JSON.parse(TripDetails?.vehicle_type)?.meet_greet_chrg;
      additional_price += JSON.parse(
        TripDetails?.vehicle_type
      )?.meet_greet_chrg;
    }
  }

  if (TripDetails?.vehicle_type) {
    price += JSON.parse(TripDetails?.vehicle_type)?.price;
  }

  if (TripDetails?.flight_number) {
    price += 15;
    additional_price += 15;
  }

  return {
    originalPrice: price,
    // add HST Tax 13% of original price and Gratuity 15% of original price\
    additional_price,
    totalPrice: price + price * 0.13 + price * 0.15,
  };
};

export const demo_data_for_summary_test = {
  booking_details: {
    from: "Toronto Pearson International Airport ",
    to: "Toronto Marriott Downtown",
    date: "2024-05-31T19:00:00.000Z",
    time: "2024-06-22T09:07:00.352Z",
    dott: ".",
    arrow: "→",
    km: "23 km",
  },
  passenger: {
    ttile_fisrt_name: "First Name",
    first_name: "Test",
    ttile_last_name: "Last Name",
    last_name: "John",
    email_name: "Email",
    email: "john@gmail.com",
    title_phone: "Phone Number",
    phone_number: "+0 2123212121",
  },
  trip_details: {
    title_extra_stop: "Extra Stop",
    extra_stop: "3",
    title_flight_number: "filght Number",
    flight_number: "PK-345",
    title_additional_information: "Additional Information",
    additional_information: "Enter Text Here...!",
    vehicle_type:
      '{"type":"luxury_suv","price":120,"meet_greet_chrg":75,"label":"Luxury Suv","bag_space":6,"passenger_space":6,"icon":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAApCAYAAABz26rUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ0SURBVHgB7ZnhdZswFIWve/I/dAK0Qd0JQjfICGzQbmA6QdMJ3A2SThB3AnsD5AlMJ2h1y1OMOUjCFgZ8Tr9zbogFgvtAek/GC5xPYvRopDAsO5HGlaDxtdGfK4vXUOjJoudxyuhVgtCiymgv20qO07JttjXPYUlEti1ttC2l78eOc1zMWjQWvFkFBkKhfrQK45EZHYa6Ju/8C8bnVRQFsw3vfobxyYa4dimaiq3IS2b0jHrM0exS2nPUdyDHdBQtD/RGj/T6lm4POM3DNl2WGH/ytqGPg4j/b3Hqle2dxcQGNWbqdMHR0fR0ooUckLQ6LaWtqyB1tVk0+mGLVp/2Ztumz/UUrr9cuFQZPDDKFRyPaiYq4Ukq6xkZDelb27ySHduZGm7Kevw3L+4kgC+oJwRXgJzAj9KeNgJsbq+NTRRaPttV707EIOi5sB1KTFuwzoXmS/tBoX4kS9wO9ErP6R2OxjlsdoGOHHe50YfAcXzkTzjWCyX90kC/X0Y/EOZBtp/4p8BxgqzQPc5p/DPOS7HNTPF8Rj87nLsKnRKP9tgCErHrRCXi6sIB8XXF52PNIZSiG4V4EsSjPPvev8Ntc3/rAbwVshi00VdcxgqRQ5UBxL572aBf6usiQ2QAHEK/MR33iEMP8QRi3pPGVv/9EAEwVWaYiJvPQv8DmJrYOlA5RPatY+2SJXHoIkIBbIx+tsxp2acxLO3fDJToAZ4kEQqA5p8wDu0bZOG3r8zVKTQHQl9wxkD7djIAXx2IrRFD4LuJlS+ACvN5As5XmQzAZXIO5i0uL5X9lTJHvS7h4sq+1P2O+QRBbznqVExvXIBujF7+Aks7nyhUPm6GAAAAAElFTkSuQmCC"}',
    title_number_of_passengers: "Number Of Passengers",
    number_of_passengers: "1 - 2",
    title_number_of_bags: "Number Of Bags",
    number_of_bags: "3 - 6",
    title_baby_seat_wheel_chair: "Baby Seat, Wheel Chair",
    baby_seat_wheel_chair: ["Toddler seat (ages 1-3), Wheel chair"],
    title_pet_seat: "Pet Seat",
    pet_seat: "1",
    title_meet_greet_services: "Meet & Greet Services - $60/Sedan, $75/Suv",
    meet_greet_services: "Yes",
  },
};

// const providedDetails = {
//   serviceId: 'service_uzcyrek',
//   templateId: 'template_lx5weeq',
//   to_name: 'John Doe',
//   to_email: 'muneebsamar13@gmail.com',
//   message: `You booked our service at ChauffeurService on ${new Date().toLocaleDateString()}`,
//   from_name: 'ChauffeurService',
//   from_email: 'muneebsamar08@gmail.com',
//   reply_to: 'muneebsamar08@gmail.com',
//   userId: 'kZSRye5Q6dyET3MAh'
// }

export const method_types = [
  {
    label: "Hourly/ As directed",
    type: "by_the_hour",
  },
  {
    label: "Airport Departure",
    type: "one_way",
  },
  {
    label: "Airport Arrival",
    type: "one_way",
  },
  {
    label: "Point To Point",
    type: "one_way",
  },
  {
    label: "Road Show",
    type: "one_way",
  },
  {
    label: "Tour",
    type: "one_way",
  },
  {
    label: "Custom Itinerary",
    type: "one_way",
  },
  {
    label: "Charter",
    type: "one_way",
  },
  {
    label: "Transfer",
    type: "one_way",
  },
  {
    label: "Prom",
    type: "by_the_hour",
  },
  {
    label: "Birthday Party",
    type: "by_the_hour",
  },
];
