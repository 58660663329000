import { useController } from "react-hook-form";
import _ from "lodash";

export const Textarea = props => {
    const { title, errors, defaultValue, isDisabled = false, ...others } = props;

    const {
      field
    } = useController({
      name: props.name,
      control: props.control,
      defaultValue: props.defaultValue
    });

    let err = _.get(errors, props.name);
  
    return (
        <>
            <div className="w-full">
            <div
                    className={`pointer-events-none font-medium text-base mb-2 ${
                         props?.rules && err ? "text-red-600" : "text-gray-600"
                    }`}
               >
                    {title}
                    {props?.rules?.required?.value === true && <span className="text-red-500 p-2">*</span>}
               </div>
                <div
                    className={` px-3 py-2 relative w-full bg-[#ECECEC] focus-within:bg-[#ECECEC] outlineStyle rounded ${
                        props?.rules && err
                             ? "focus-within:border-red-600 border-red-600"
                             : "focus-within:border-teal-c-900"
                   }`}
                >
                <textarea 
                className={`block w-full appearance-none focus:outline-none bg-transparent focus:ring-0 text-lg font-normal text-black ${
                    isDisabled && "bg-gray-50 cursor-not-allowed"
               }`}
                placeholder={props.placeholder || ''}
                onChange={e => {
                    field.onChange(e.target.value);
                    if(props.onChange){
                        props.onChange(e, field.value);
                    }
                }}
                value={field.value}
                name={field.name}
                inputRef={field.ref}></textarea>
                </div>
                {props.rules && err && (
                    <p className=" text-xs text-red-600 h-3" id="email-error">
                        {props.rules && err && props.rules && err?.message}
                    </p>
                )}
            </div>
        </>
    );
  }