import React from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";

export const InputField = React.forwardRef((props, ref) => {
     const { title, errors, defaultValue, isDisabled = false, showTitle = true, ...others } = props;

     let err = _.get(errors, props.name);
     return (
          <div className="w-full">
               {showTitle && <div
                    className={`pointer-events-none font-medium text-base mb-2 ${
                         props?.rules && err ? "text-red-600" : "text-gray-600"
                    }`}
               >
                    {title}
                    {props?.rules?.required?.value === true && <span className="text-red-500 p-2">*</span>}
               </div>}
               <div
                    className={`${
                         props?.height ? props.height + ' py-0.5' : "h-[45px] py-2"
                    } px-3 relative w-full ${showTitle ? `bg-[#ECECEC] focus-within:bg-[#ECECEC]` : 'bg-white'} outlineStyle rounded ${
                         props?.rules && err
                              ? "focus-within:border-red-600 border-red-600"
                              : "focus-within:border-teal-c-900"
                    }`}
               >
                    <Controller
                         name={props?.name}
                         control={props?.control}
                         rules={props?.rules}
                         defaultValue={defaultValue}
                         render={({ field }) => (
                              <>
                                   <input
                                        {...props}
                                        {...field}
                                        onChange={(e) => {
                                             field.onChange(e.target.value);
                                             if (props.onChange) {
                                                  props.onChange(e, props?.name);
                                             }
                                        }}
                                        min={props.min}
                                        disabled={isDisabled}
                                        placeholder={props.placeholder ? props.placeholder : ""}
                                        value={field.value}
                                        className={`block w-full appearance-none focus:outline-none bg-transparent focus:ring-0 text-lg font-normal text-black ${
                                             isDisabled && "bg-gray-50 cursor-not-allowed"
                                        }`}
                                        {...others}
                                   />
                              </>
                         )}
                    />
               {props.rules && err && (
                    <p className=" text-xs text-red-600 h-3 mt-2 -mr-8" id="email-error">
                         {props.rules && err && props.rules && err?.message}
                    </p>
               )}
               </div>
          </div>
     );
});
