  import React, { useEffect, useState } from "react";
  import confirmedDone from "../../assets/images/confirmed.png";
  import groupluxury from "../../assets/images/Groupluxury.png";
  import moment from "moment/moment";
  function BookingConfirmation({ paymentStatus }) {
    return (
      <div className="flex items-center h-screen">
        <div className="mx-auto max-w-3xl overflow-auto rounded-lg shadow-md text-base font-light">
          <div className="bg-black px-8 py-8">
            <div>
              <img
                src={confirmedDone}
                alt="confirmedDone"
                className="w-20 h-20 mx-auto mt-8"
              />
            </div>
            <div className="text-center mt-4 space-y-3">
              <h1 className="text-2xl font-bold text-white">
                Your booking is confirmed! 🙌
              </h1>
              <p className="text-gray-200">
                A confirmation email has been sent to you on {paymentStatus?.paymentIntent?.receipt_email}
              </p>
            </div>
            <hr className="my-5 mx-auto max-w-xl bg-gray-200" />
            <div className="text-white mx-auto sm:max-w-[600px] max-w-[300px] flex items-center justify-between">
              {paymentStatus?.paymentIntent?.metadata?.flight_number && <div className="space-y-1.5">
                <p>Flight Number</p>
                <p className="text-white text-base font-semibold">{paymentStatus?.paymentIntent?.metadata?.flight_number}</p>
              </div>}
              <p className="-mr-5 w-72 leading-none">
                Please note: Your driver will contact you 1hr prior to your{" "}
                <span className="underline"> pick-up</span>
              </p>
            </div>
            <div className="flex gap-5 mt-8 justify-center">
              <div className="flex-1 rounded-xl border text-center border-gray-400 p-4 space-y-5 h-auto min-h-32">
                <p className="text-white text-lg">
                  {paymentStatus?.paymentIntent?.metadata?.from}
                </p>
                <p className="font-bold text-base text-white ">{paymentStatus?.paymentIntent?.metadata?.time}</p>
              </div>
              <div className="flex-1 text-white text-center h-auto min-h-32">
                <p className="font-bold">{paymentStatus?.paymentIntent?.metadata?.date}</p>
                <img
                  src={groupluxury}
                  alt="groupluxury"
                  className="w-14 h-10 mx-auto mt-3 mb-5"
                />
                <div className="space-y-3">
                  <hr className="w-20 h-1.5 mx-auto" />
                  <p>{paymentStatus?.paymentIntent?.metadata?.distance}</p>
                </div>
              </div>

              <div className="flex-1 rounded-xl border text-center border-gray-400 p-4 space-y-5 h-auto min-h-32">
                <p className="text-white text-lg">{paymentStatus?.paymentIntent?.metadata?.to}</p>
                <p className="font-bold text-base text-white">{paymentStatus?.paymentIntent?.metadata?.estimated_time ? `Estimated arrival in ${paymentStatus?.paymentIntent?.metadata?.estimated_time}` : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="bg-grayLight px-4 py-4 flex justify-between pt-10">
            <div className=" mb-2">
              <div>
                Passenger Details : <span className="font-bold ">{paymentStatus?.paymentIntent?.metadata?.first_name} {paymentStatus?.paymentIntent?.metadata?.last_name}</span>
              </div>
              <div className="flex items-center gap-4">
                <p>
                  seat : <span className="font-bold">{paymentStatus?.paymentIntent?.metadata?.number_of_passengers}</span>
                </p>
                <p>
                  Bags : <span className="font-bold">{paymentStatus?.paymentIntent?.metadata?.number_of_bags}</span>
                </p>
              </div>
            </div>{" "}
            <div className="mb-2">
              <p className="text-nowrap">Payment Summary</p>
              <p className="text-nowrap">{paymentStatus?.paymentIntent?.metadata?.vehicle_type || '-'}</p>
              <p className="text-nowrap">Additional Services</p>
              <p className="text-nowrap">Total</p>
            </div>
            <div className="font-bold mb-2">
              <p>-</p>
              <p>${paymentStatus?.paymentIntent?.metadata?.vehicle_price || 0}</p>
              <p>${paymentStatus?.paymentIntent?.metadata?.additional_price || 0}</p>
              <p>${(paymentStatus?.paymentIntent?.amount / 100).toFixed(0)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default BookingConfirmation;
