import React, { useEffect, useState } from 'react'
import { HiMenu, HiX } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import logo from '../assets/air.svg' 
const header = ({ header, setBooking }) => {
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [activeSection, setActiveSection] = useState('');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    let navigation = [
      { name: "Home", href: "#home" },
      { name: "About", href: "#about" },
      { name: "Cities", href: "#cities" },
      { name: "Services", href: "#services" },
      { name: "Vehicles", href: "#Vehicles" },
      { name: "Contact", href: "#Contact" },
    ];

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }

            // Determine which section is currently in view
            const sections = navigation.map(item => item.href.slice(1));
            for (const section of sections.reverse()) {
                const element = document.getElementById(section);
                if (element && element.getBoundingClientRect().top <= 100) {
                    setActiveSection(section);
                    break;
                }
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
      <header className={`w-full`}>
        <nav className="py-0 px-1 mt-3 sm:py-3 sm:px-16 sm:mt-0 md:px-1 lg:h-auto">
          <div className="flex items-center justify-between">
            <div className="text-2xl homenaje-regular cursor-pointer text-baseColor hidden lg:block">
              <img src={logo} alt='logo' className='size-32 bg-inherit'  />
            </div>
            {/* for larger devices */}
            <ul className="lg:flex items-center gap-x-8 hidden text-baseColor Helvetica-regular">
              {navigation?.map((items) => {
                const { name, href } = items;
                const isActive = activeSection === href.slice(1);
                return (
                  <a
                    key={name}
                    href={href}
                    className={`block font-medium text-lg transition-all duration-200 ${
                      isActive ? 'border-b-2 border-baseColor text-baseColor' : 'hover:border-b-2 hover:border-baseColor hover:text-baseColor'
                    }`}
                  >
                    {name}
                  </a>
                );
              })}
            </ul>
            <div>
              <button
                className={` ${
                  header
                    ? "bg-baseColor text-black duration-300"
                    : " text-black bg-baseColor"
                } hidden lg:block px-8 py-4 rounded-xl font-bold text-base`}
                onClick={() => {
                  navigate("/booking");
                  setBooking({
                    open: true,
                    from: "header",
                  });
                }}
              >
                Schedule Ride
              </button>
            </div>
            <button
              onClick={toggleMenu}
              className="lg:hidden z-50 fixed right-7 top-5 text-baseColor text-3xl"
            >
              {isMenuOpen ? (
                <HiX className="size-6" />
              ) : (
                <HiMenu className="size-6" />
              )}
            </button>
          </div>
          {/* nav items for small devices */}
          {isMenuOpen && (
            <ul className="fixed mt-10 z-30 scroll-smooth w-11/12 ml-5 sm:ml-0 md:ml-5 sm:w-[84%] md:w-[92%] bg-black text-white rounded-2xl pb-3">
              {navigation?.map((items) => {
                const { name, href } = items;
                const isActive = activeSection === href.slice(1);
                return (
                  <li
                    key={name}
                    onClick={() => setIsMenuOpen(false)}
                    className={`my-2 ml-3 p-1 transition-all duration-300 ${
                      isActive ? 'border-b-2 border-baseColor w-16' : ''
                    }`}
                  >
                    <a
                      href={href}
                      className={`text-base font-semibold Helvetica-regular ${
                        isActive ? 'text-baseColor' : 'hover:text-baseColor'
                      }`}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
      </header>
    );
}

export default header