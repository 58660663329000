import React, { useEffect, useRef, useState } from "react";
import { Cursol } from '../assets/Carousel/index';

const sliders = [
  {
    url: Cursol.Toranto,
    h3: "Top city",
    text: "Toronto",
  },
  {
    url: Cursol.Ottawa,
    h3: "Top city",
    text: "Mississauga",
  },
  {
    url: Cursol.Burlington,
    h3: "Top city",
    text: "Brampton",
  },
  {
    url: Cursol.Vaughan,
    h3: "Top city",
    text: "Vaughan",
  },
  {
    url: Cursol.Markham,
    h3: "Top city",
    text: "Markham",
  },
  {
    url: Cursol.Oakville,
    h3: "Top city",
    text: "Oakville",
  },
  {
    url: Cursol.Hamilton,
    h3: "Top city",
    text: "Hamilton",
  },
  {
    url: Cursol.London,
    h3: "Top city",
    text: "London",
  },
  {
    url: Cursol.Kingston,
    h3: "Top city",
    text: "Kingston",
  },
];

const MobileCarousel = ({ currentIndex, sliders }) => {
  return (
    <div className="flex flex-col w-[80%] mx-auto">
          <img
            src={sliders[currentIndex].url}
            alt={`Slide ${currentIndex}`}
            className="rounded-t-md shadow w-full h-52"
          />
          <div className="p-3 text-black bg-white rounded-b-md w-full">
            <h3 className="text-sm md:text-xs opacity-50">{sliders[currentIndex].h3}</h3>
            <p className="text-base md:text-sm font-semibold">{sliders[currentIndex].text}</p>
          </div>
    </div>
  );
};

const DesktopCarousel = ({ sliders }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
      {sliders.map((slider, index) => (
        <div key={index} className="flex flex-col">
          <img
            src={slider.url}
            alt={`Slide ${index}`}
            className="rounded-t-md shadow-2xl h-52"
          />
          <div className=" py-3 px-2.5 space-y-1 text-black bg-white rounded-b-md w-full">
            <h3 className="text-sm opacity-50">{slider.h3}</h3>
            <p className="text-lg font-medium leading-4 Helvetica-regular">
              {slider.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);

  const intervalRef = useRef(null);

  const prevSlide = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? sliders.length - 1 : prevIndex - 1));
    resetInterval();
  };

  const nextSlide = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) => (prevIndex === sliders.length - 1 ? 0 : prevIndex + 1));
    resetInterval();
  };

  const resetInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === sliders.length - 1 ? 0 : prevIndex + 1));
    }, 3000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 450);
    };

    window.addEventListener('resize', handleResize);
    resetInterval();

    return () => {
      clearInterval(intervalRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="bg-grayLight p-[4%] pt-10 lg:p-20 xl:p-[100px] 2xl:px-28">
      <div className="relative w-full mx-auto overflow-hidden">
        <div className="text-center mb-7">
          <div className="flex items-center justify-center gap-4 mb-8">
            <div className="h-1 w-16 bg-gray-400"></div>
            <h2 className="text-3xl font-semibold text-gray-400 Helvetica-regular">
              Cities
            </h2>
            <div className="h-1 w-16 bg-gray-400"></div>
          </div>
          <p className="text-[#d4af37] text-2xl font-bold">
            We mainly operate in Ontario, <br /> For inquiries outside,{" "}
            <span className="underline decoration-1 underline-offset-[0.3rem]">
              please contact us!
            </span>
          </p>
        </div>
        {isMobile ? (
          <MobileCarousel currentIndex={currentIndex} sliders={sliders} />
        ) : (
          <DesktopCarousel sliders={sliders} />
        )}
        <div className="sm:hidden">
          <button
            className="absolute top-2/3 left-0 transform -translate-y-1/2 p-2 bg-gray-700 text-white"
            onClick={prevSlide}
          >
            {"<"}
          </button>
          <button
            className="absolute top-2/3 right-0 transform -translate-y-1/2 p-2 bg-gray-700 text-white"
            onClick={nextSlide}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
