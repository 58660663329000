import React, { useState } from "react";
// import { demo_data_for_summary_test, sendEmail } from '../../../../components/common.lib'
import moment from "moment";
import Bag from "../../../../assets/icons/bag.png";
import { HiOutlineUser } from "react-icons/hi2";
import { getPrice } from "../../../../components/common.lib";

function ConfirmationDetails({ formHandler, method, setActiveTab }) {
  const [editBookingDetails, setEditBookingDetails] = useState(false);
  const values = formHandler.getValues();
  const { booking_details, passenger, trip_details } = values;
  const {
    icon,
    bag_space,
    label,
    passenger_space,
    price,
    type,
  } = JSON.parse(trip_details?.vehicle_type);
  const totalPrice = getPrice({ ...values, method: formHandler.watch('method')?.type });

  const onEditHandler = () => {
    setActiveTab("trip-details");
  };

  return (
    <>
      <div className="mt-5 md:my-6 rounded-2xl bg-grayLight py-4 px-5 tracking-tighter mx-auto p-4">
        <div className="flex flex-col gap-2">
          <div className="font-medium text-lg tracking-wide md:text-sm md:font-semibold lg:text-sm lg:font-semibold xl:text-sm xl:font-semibold flex items-center justify-between">
            <span className="font-semibold text-xl">Confirm Details</span>
            <span
              onClick={() => onEditHandler()}
              className="text-[#007AFF] cursor-pointer"
            >
              Edit
            </span>
          </div>
          <span className="font-medium text-sm mt-2 tracking-wide">
            {moment(values?.booking_details?.date, "MM/DD/YYYY").format(
              "dddd, MMM DD, YYYY"
            )}{" "}
            at{" "}
            {moment(values?.booking_details?.time, "HH:mm").format("hh:mm A")}
            <span className="ml-12 md:ml-16">
              {values?.method?.label}
            </span>
          </span>
          {formHandler.watch('method')?.type == "one_way" && (
            <>
              <div className="flex items-center gap-3 tracking-wide text-sm">
                <p>{booking_details?.from}</p>
                <p className="basis-12 md:basis-0 lg:basis-0 xl:basis-0">→</p>
                <p>{booking_details?.to}</p>
              </div>
              <div className="flex items-center gap-1.5 tracking-[0.5px] text-sm md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                <span>
                  Estimated arrival in {booking_details.estimated_arrival}
                </span>
                <span>.</span>
                <span>{booking_details.distance}</span>
              </div>
            </>
          )}
          {formHandler.watch('method')?.type == "by_the_hour" && (
            <>
              <div className="flex w-fit items-center gap-4 font-normal text-lg md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                <div> Pickup Location</div>
                <div>{booking_details?.from}</div>
              </div>
              <div className="flex w-fit items-center gap-4 font-normal text-lg md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                <div>Booking Duration</div>
                <div>{booking_details?.duration} hours</div>
              </div>
            </>
          )}
        </div>
        <div className="mt-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 tracking-wide leading-6">
            <div className="space-y-1 tracking-wide">
              <h2 className="font-medium text-sm opacity-60">First Name</h2>
              <h2 className="font-medium text-sm">{passenger?.first_name}</h2>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Last Name</p>
              <p className="font-medium text-sm">{passenger?.last_name}</p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Email</p>
              <p className="font-medium text-sm">{passenger?.email}</p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Phone Number</p>
              <p className="font-medium text-sm">{passenger?.phone_number}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 mt-3">
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">
                Number Of Passengers
              </p>
              <p className="font-medium text-sm">
                {trip_details?.number_of_passengers}
              </p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Number of Bags</p>
              <p className="font-medium text-sm">
                {trip_details?.number_of_bags}
              </p>
            </div>
            <h2 className="font-semibold text-xl md:col-span-2 lg:col-span-2 xl:col-span-2">
              Additional Services
            </h2>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">
                Baby Seat, Wheel Chair
              </p>
              <p className="font-medium text-sm">
                {trip_details?.baby_seat_wheel_chair?.map((item) => (
                  <p>{item}</p>
                ))}
              </p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Pet Seat</p>
              <p className="font-medium text-sm">{trip_details?.pet_seat}</p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">Extra Stop</p>
              <p className="font-medium text-sm">{trip_details?.extra_stop}</p>
            </div>
            <div className="space-y-1 tracking-wide">
              <p className="font-medium text-sm opacity-60">
                Meet & Greet Services - $60/Sedan, $75/Suv
              </p>
              <p className="font-medium text-sm">
                {trip_details?.meet_greet_services}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3 space-y-3">
          <div className="space-y-1 tracking-wide">
            <p className="font-medium text-sm opacity-60">Flight Number</p>
            <p className="font-medium text-sm">{trip_details?.flight_number}</p>
          </div>
          <div className="space-y-1 tracking-wide">
            <p className="font-medium text-sm opacity-60">
              Additional Information
            </p>
            <p className="font-medium text-sm">
              {trip_details?.additional_information}
            </p>
          </div>
        </div>
        {/* Vehicle */}
        <div className="py-5">
          <h2 className="font-medium text-2xl">Vehicle</h2>
          <div className="flex items-center gap-5 mt-4">
            <input
              type="radio"
              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all checked:border-red-900  checked:before:bg-red-900 checked:ring-2"
              // register in react hook form
            />
            <div className="flex items-center gap-4">
              <img src={icon} className="w-10 h-8" />
              <div>
                <h2 class="font-semibold tracking-wide text-gray-800">
                  {label}
                </h2>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-1.5">
                    <HiOutlineUser className="size-3" />
                    <p className="text-sm font-medium tracking-wide text-gray-600 dark:text-gray-400">
                      {passenger_space}
                    </p>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <img src={Bag} className="size-3" />
                    <p className="text-sm font-medium tracking-wide text-gray-600 dark:text-gray-400">
                      {bag_space}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="font-semibold text-xl">Grand Total</h2>
          <div className="flex items-centr justify-between">
            <p className="font-medium text-sm opacity-60">{label}</p>
            <p className="font-medium text-sm">${price}</p>
          </div>
          <div className="flex items-centr justify-between">
            <p className="font-medium text-sm opacity-60">
              Additional services:
            </p>
            <p className="font-medium text-sm">
              ${totalPrice.additional_price}
            </p>
          </div>
          <div className="flex items-centr justify-between">
            <p className="font-medium text-sm opacity-60">HST:</p>
            <p className="font-medium text-sm">13%</p>
          </div>
          <div className="flex items-centr justify-between">
            <p className="font-medium text-sm opacity-60">Gratuity</p>
            <p className="font-medium text-sm">15%</p>
          </div>
          <div className="flex items-centr justify-between">
            <p className="font-semibold  text-lg">Total Price</p>
            <p className="font-semibold text-lg">${totalPrice.totalPrice}</p>
          </div>
          {/* <p className='text-sm opacity-60 space-y-0'>Pricing including VAT</p> */}
        </div>
        {/* <button
            className="bg-black text-baseColor font-medium tracking-wide text-sm py-2 px-4 rounded-lg w-full mt-4 md:7 lg:mt-8 xl:mt-9"
            type="submit"
            
          >
            Confirm
          </button> */}
      </div>
    </>
  );
}

export default ConfirmationDetails;
