import { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import Header from "../components/header";
import CarouselDefault from "../components/Carousel";
import About from "./pages/About";
import Services from "../components/Services";
import Vehicles from "../components/Vehicles";
import Contact from "../components/contact";
import Faqs from "../components/accordion/Faqs";
import { Footer } from "../components/footer";
import BookNow from "../components/BookNow";
export default function ChauffeurService({
  setBooking,
  formHandler,
}) {
  const [header, setHeader] = useState();
  const scrollHeader = () => {
    if (window.scrollY >= 50) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHeader);
    return window.addEventListener("scroll", scrollHeader);
  }, []);
  return (
    <>
      <div>
          <div className="hidden lg:flex gap-4 bg-black items-center text-white justify-end pr-16 py-2 h-12">
            <p className="flex items-center gap-2 text-xs">
              <BsTelephone color="#D4AF37" size="13.29px" />
              +1 647 617 1387
            </p>
            <p className="flex items-center gap-2 text-xs">
              <MdOutlineMailOutline color="#D4AF37" size="13.29px" />
              info@aircanadalimo.com
            </p>
          </div>
        <div
          id="home"
          className="border-gray-200 bg-white bg-home-image bg-center bg-cover h-[520px] lg:h-[770px] 2xl:h-[88vh] overflow-hidden"
        >
          <div
            className={`${
              header ? "fixed top-0 z-20" : "z-10"
            } w-full lg:bg-black transition-all duration-100`}
          >
            <Header 
              header={header} 
              setBooking={setBooking}
            />
          </div>
          <div
            className="absolute homenaje-regular left-[4%] lg:left-[12.2%] xl:left-[6.4%] top-[25%] lg:top-[50%] 2xl:top-[34%]"
          >
            <h3 className="text-baseColor text-xs font-bold">
              A CHAUFFEUR FOR A RIDE?
            </h3>
            <p className="text-white text-2xl md:text-3xl lg:text-3xl xl:text-[2.7rem] xl:leading-[3.2rem] 2xl:text-[3.5rem]">
              Airport Shuttle or City Tour? Make Your Online
              <br />
              Reservation With{" "}
              <span className="text-baseColor underline decoration-2 underline-offset-4 text-[2rem] lg:text-4xl 2xl:text-6xl">
                Air Canada Limo
              </span>
            </p>
          </div>
        </div>
        <div>
          <BookNow
            setBooking={setBooking}
            formHandler={formHandler}
          />
        </div>
        <main className="pt-10">
            <div id="about">
              <About />
            </div>
            <div id="cities">
              <CarouselDefault />
            </div>
            <div id="services">
              <Services />
            </div>
            <div id="Vehicles">
              <Vehicles />
            </div>
            <div id="Contact">
              <Contact />
            </div>
            <div>
              <Faqs />
            </div>
            <div>
              <Footer />
            </div>
        </main>
      </div>
    </>
  );
}
