import React, { useEffect, useRef, useState } from "react";
import { DatePickerField } from "./ReactHookFields/datePicker";
import { ReactSelectField } from "./ReactHookFields/SelectField";
import { InputField } from "./ReactHookFields/input";
import { meta, method_types } from "./common.lib";
import moment from "moment";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useWatch } from "react-hook-form";

const BookNow = ({ setBooking, formHandler }) => {
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const autocompleteRefFrom = useRef(null);
  const autocompleteRefTo = useRef(null);
  const [timeSlots, setTimeSlots] = useState({
    minTime: new Date(),
    maxTime: new Date().setHours(23, 59, 59, 999)
  });
  const currentDate = moment().format("MM/DD/YYYY");
  const dateWatch = useWatch({
    control: formHandler.control,
    name: 'booking_details.date'
  });
  console.log(dateWatch, "dateWatch");
  
  // Set default value for date if not already set
  useEffect(() => {
    if (!dateWatch) {
      formHandler.setValue("booking_details.date", currentDate);
    }
  }, [dateWatch, currentDate, formHandler]);

  const handlePlaceChangedFrom = () => {
    const autocomplete = autocompleteRefFrom.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      formHandler.setValue("booking_details.from", place.formatted_address);
    }
  };

  const handlePlaceChangedTo = () => {
    const autocomplete = autocompleteRefTo.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      formHandler.setValue("booking_details.to", place.formatted_address);
    }
  };

  const onSubmit = (data) => {
    navigate("/booking");
    setBooking({
      open: true,
      from: "booknow",
    });
  };
  
  const getError = (name) => {
    return _.get(formHandler.formState.errors, name);
  };

  useEffect(() => {
    if (dateWatch) {
      const selectedDate = moment(dateWatch, "MM/DD/YYYY");
      const currentTime = moment();
      
      if (selectedDate.isSame(currentTime, 'day')) {
        setTimeSlots({
          minTime: new Date(),
          maxTime: new Date().setHours(23, 59, 59, 999)
        });
      } else {
        setTimeSlots({
          minTime: new Date().setHours(0, 0, 0, 0),
          maxTime: new Date().setHours(23, 59, 59, 999)
        });
      }
    }
  }, [dateWatch]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="relative mx-3 md:mx-4 lg:mx-16 xl:mx-[100px] -mt-[37%] sm:-mt-[16%] xl:-mt-[9%] ">
      <div className=" w-full rounded-[2.5rem] px-8 pt-10 bg-gradient-to-b from-zinc-100 via-zinc-300 to-neutral-100 shadow-lg">
        <ul className="relative flex space-x-5 text-base font-medium tracking-wider border-b-2 border-black border-opacity-30">
          <div className="w-full sm:w-1/2 lg:w-1/3 mb-2">
          <ReactSelectField
            showTitle={false}
            name='method'
            control={formHandler.control}
            rules={{
              required: {
                value: true,
                message: "method is required",
              },
            }}
            errors={formHandler.formState.errors}
            optionData={method_types}
            onChange={(e) =>
              formHandler.setValue("method", e)
            }
            title='Select Service Type'
            defaultValue={method_types[0]}
            placeholder="Select Service Type"
            // uniqueKey={"value"}
          />
          </div>
        </ul>
        <div className="md:grid lg:grid md:grid-cols-6 lg:grid-cols-5 gap-x-5 items-center lg:gap-12 mt-5 md:mt-3 md:mb-4 lg:pb-8 pb-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 items-center mt-4 px-1 gap-y-2 gap-x-4 col-span-4">
            <div>
              <p className="font-medium text-lg text-black opacity-60 pl-1.5">
              Pick-up
              </p>
              <div className={`bg-white text-black rounded-lg ${getError("booking_details.from")?.message ? " mb-2" : "" } ` }>
                <Autocomplete
                  onLoad={(autocomplete) =>
                    (autocompleteRefFrom.current = autocomplete)
                  }
                  onPlaceChanged={handlePlaceChangedFrom}
                >
                  <InputField
                    showTitle={false}
                    name="booking_details.from"
                    control={formHandler.control}
                    rules={{
                      required: {
                        value: true,
                        message: "From is required",
                      },
                    }}
                    errors={formHandler.formState.errors}
                    placeholder="Address, airport, hotel"
                    height="h-[35px]"
                  />
                </Autocomplete>
              </div>
            </div>
            <div>
              <p className="font-medium text-lg text-black opacity-60 pl-1.5">
                {formHandler?.watch('method')?.type === "by_the_hour" ? "Duration" : "Drop-off"}
              </p>
              <div className={`bg-white text-black rounded-lg ${getError("booking_details.from")?.message ? " mb-2" : "" } ` }>
                {formHandler?.watch('method')?.type && formHandler?.watch('method')?.type == "by_the_hour" ? (
                  <ReactSelectField
                    showTitle={false}
                    name="booking_details.duration"
                    control={formHandler.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Duration is required",
                      },
                    }}
                    errors={formHandler.formState.errors}
                    optionData={meta.duration}
                    // onChange={(e) =>
                    //   formHandler.setValue("booking_details.duration", e)
                    // }
                    placeholder="select"
                    uniqueKey={"value"}
                  />
                ) : (
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (autocompleteRefTo.current = autocomplete)
                    }
                    onPlaceChanged={handlePlaceChangedTo}
                  >
                    <InputField
                      showTitle={false}
                      name="booking_details.to"
                      control={formHandler.control}
                      rules={{
                        required: {
                          value: true,
                          message: "To is required",
                        },
                      }}
                      errors={formHandler.formState.errors}
                      height="h-[35px]"
                      placeholder="Address, airport, hotel"
                    />
                  </Autocomplete>
                )}
              </div>
            </div>
            <div>
              <p className="font-medium text-lg text-black opacity-60 pl-1.5">
                Date
              </p>
              <div className={`bg-white text-black rounded-lg flex items-center gap-2 ${getError("booking_details.from")?.message ? " mb-2" : "" } ` }>
                <DatePickerField
                  showTitle={false}
                  name="booking_details.date"
                  control={formHandler.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Date is required",
                    },
                  }}
                  errors={formHandler.formState.errors}
                  dateTime={false}
                  // onChange={(e) =>
                  //   formHandler.setValue(
                  //     "booking_details.date",
                  //     moment(e).format("MM/DD/YYYY")
                  //   )
                  // }
                  minDate={new Date()}
                  placeholder=""
                />
              </div>
            </div>
            <div>
              <p className="font-medium text-lg text-black opacity-60 pl-1.5">
                Time
              </p>
              <div className={`bg-white text-black rounded-lg flex items-center gap-2 ${getError("booking_details.from")?.message ? " mb-2" : "" } ` }>
                <DatePickerField
                  showTitle={false}
                  name="booking_details.time"
                  control={formHandler.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Time is required",
                    },
                  }}
                  errors={formHandler.formState.errors}
                  showTimeSelectOnly={true}
                  placeholder="Time"
                  defaultValue={moment().format("h:mm a")}
                  timeIntervals={15}
                  timeCaption="Time"
                  showTimeSelect={true}
                  dateFormat="h:mm a"
                  dateTime={false}
                  {...timeSlots}
                />
              </div>
            </div>
          </div>
          <div className="w-full mt-12 pb-2 md:col-span-2">
            <button
              className="w-full bg-black font-bold text-base leading-4 tracking-tight text-baseColor rounded-lg px-4 py-3 lg:py-2.5"
              onClick={formHandler.handleSubmit(onSubmit)}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
