import React, { useEffect, useRef, useState } from "react";
import { InputField } from "../../../components/ReactHookFields/input";
import { DatePickerField } from "../../../components/ReactHookFields/datePicker";
import moment from "moment";
import { ReactSelectField } from "../../../components/ReactHookFields/SelectField";
import { meta, method_types } from "../../../components/common.lib";
import { useForm, useWatch } from "react-hook-form";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const Tabs = ({ tabsData, formHandler, activeTab, setActiveTab, booking }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();

  const [editBookingDetails, setEditBookingDetails] = useState(false);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const autocompleteRefFrom = useRef(null);
  const autocompleteRefTo = useRef(null);
  const [timeSlots, setTimeSlots] = useState({
    minTime: new Date(),
    maxTime: new Date().setHours(23, 59, 59, 999),
  });
  const dateWatch = useWatch({
    control: control,
    name: "date",
  });
  const currentDate = moment().format("MM/DD/YYYY");

  const values = formHandler.getValues();
  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const renderedTabs = tabsData.map((tab, index) => (
    <Tab
      key={tab.id}
      title={tab.title}
      isActive={tab.id === activeTab}
      // onClick={() => handleTabClick(tab.id)}
      index={index}
      tabsData={tabsData}
      url={tab?.url}
    />
  ));

  const activeContent = tabsData.find((tab) => tab.id === activeTab).content;

  const onOpenEditHandler = () => {
    reset(values.booking_details);
    setEditBookingDetails(true);
  };

  const onCancelHandler = () => {
    setEditBookingDetails(false);
  };

  const onSaveHandler = () => {
    formHandler.setValue("booking_details", { ...getValues(), time: moment(getValues().time, "h:mm a").format("HH:mm") });
    setEditBookingDetails(false);
  };

  React.useEffect(() => {
    if (booking.from == "header" || !formHandler?.getValues().booking_details) {
      setEditBookingDetails(true);
    }
  }, [booking]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (dateWatch) {
      const selectedDate = moment(dateWatch, "MM/DD/YYYY");
      const currentTime = moment();

      if (selectedDate.isSame(currentTime, "day")) {
        setTimeSlots({
          minTime: new Date(),
          maxTime: new Date().setHours(23, 59, 59, 999),
        });
      } else {
        setTimeSlots({
          minTime: new Date().setHours(0, 0, 0, 0),
          maxTime: new Date().setHours(23, 59, 59, 999),
        });
      }
    }
  }, [dateWatch]);

  (async function calculateRoute() {
    if (values?.booking_details?.from || values?.booking_details?.to) {
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: values?.booking_details?.from,
        destination: values?.booking_details?.to,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      formHandler.setValue(
        "booking_details.distance",
        results.routes[0].legs[0].distance.text
      );
      //// astimated arrivel
      formHandler.setValue(
        "booking_details.estimated_arrival",
        results.routes[0].legs[0].duration.text
      );
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
    }
    // eslint-disable-next-line no-undef
  })();

  const handlePlaceChangedFrom = () => {
    const autocomplete = autocompleteRefFrom.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setValue("from", place.formatted_address);
    }
  };

  const handlePlaceChangedTo = () => {
    const autocomplete = autocompleteRefTo.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setValue("to", place.formatted_address);
    }
  };

  useEffect(() => {
    if (!dateWatch) {
      setValue("date", currentDate);
    }
  }, [dateWatch, currentDate, formHandler]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="Helvetica-regular">
      <div className="space-x-3 overflow-auto text-nowrap mt-7 mb-5 flex items-center">
        {renderedTabs}
      </div>
      {activeTab != "confirm-details" &&
        activeTab != "payment-details" &&
        (editBookingDetails ? (
          <>
            <div className="text-2xl font-semibold mb-4">Booking Details:</div>
            <ul className="relative items-center mb-4 w-40% flex space-x-4 text-base font-medium tracking-wider border-b-2 border-black border-opacity-30 pt-3.5 pb-2.5">
              <div className="w-full sm:w-1/2 lg:w-1/3 mb-2">
                <ReactSelectField
                  showTitle={true}
                  name="method"
                  control={formHandler.control}
                  rules={{
                    required: {
                      value: true,
                      message: "method is required",
                    },
                  }}
                  title={'Select Service Type'}
                  errors={formHandler.formState.errors}
                  optionData={method_types}
                  onChange={(e) => formHandler.setValue("method", e)}
                  defaultValue={method_types[0]}
                  placeholder="Select Service Type"
                />
              </div>
            </ul>
            <div className="grid grid-cols-1 items-center md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
              <Autocomplete
                onLoad={(autocomplete) =>
                  (autocompleteRefFrom.current = autocomplete)
                }
                onPlaceChanged={handlePlaceChangedFrom}
              >
                <InputField
                  title="Pick-up"
                  name="from"
                  placeholder="Pick-up"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "From is required",
                    },
                  }}
                />
              </Autocomplete>
              {!formHandler.watch('method')?.type ||
              formHandler.watch('method')?.type == "one_way" ? (
                <Autocomplete
                  onLoad={(autocomplete) =>
                    (autocompleteRefTo.current = autocomplete)
                  }
                  onPlaceChanged={handlePlaceChangedTo}
                >
                  <InputField
                    title="Drop-off"
                    name="to"
                    placeholder="Drop-off"
                    control={control}
                    errors={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "To is required",
                      },
                    }}
                  />
                </Autocomplete>
              ) : (
                <ReactSelectField
                  name="duration"
                  placeholder="select"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Duration is required",
                    },
                  }}
                  errors={errors}
                  optionData={meta.duration}
                  title="Duration"
                  // onChange={(e) => setValue("duration", e)}
                  uniqueKey={"value"}
                />
              )}
              <DatePickerField
                title="Date"
                name="date"
                control={control}
                errors={errors}
                dateTime={false}
                rules={{
                  required: {
                    value: true,
                    message: "Date is required",
                  },
                }}
                onChange={(e) =>
                  formHandler.setValue("date", moment(e).format("MM/DD/YYYY"))
                }
                minDate={new Date()}
                placeholder=""
                defaultValue={moment(new Date()).format("MM/DD/YYYY")}
              />
              <DatePickerField
                name="time"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Time is required",
                  },
                }}
                errors={errors}
                showTimeSelectOnly={true}
                title="Time"
                defaultValue={moment().format("h:mm a")}
                timeIntervals={15}
                timeCaption="Time"
                showTimeSelect={true}
                dateFormat="h:mm a"
                dateTime={false}
                {...timeSlots}
              />
              <div className="gap-6 flex items-center justify-end w-full md:col-span-2 lg:col-span-2 xl:col-span-2">
                <button
                  className=" font-bold text-base leading-4 tracking-tight text-[#007AFF] rounded-lg px-6 py-3"
                  onClick={handleSubmit(onCancelHandler)}
                >
                  Cancel
                </button>
                <button
                  className="w- bg-[#007AFF] font-bold text-base leading-4 tracking-tight text-white rounded-lg px-6 py-3"
                  onClick={handleSubmit(onSaveHandler)}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-5 md:my-6 rounded-2xl bg-grayLight flex flex-col gap-2 py-7 px-6 tracking-tighter md:max-w-xl lg:max-w-3xl xl:max-w-4xl mx-auto">
            <div className="font-medium text-lg xs:tracking-tight tracking-wide md:text-sm md:font-semibold lg:text-sm lg:font-semibold xl:text-sm xl:font-semibold flex items-center justify-between">
              <span>
                {moment(values?.booking_details?.date, "MM/DD/YYYY").format(
                  "dddd, MMM DD, YYYY"
                )}{" "}
                at{" "}
                {moment(values?.booking_details?.time, "HH:mm").format(
                  "hh:mm A"
                )}
              </span>
              <span className="hidden md:inline sm:inline lg:inline xl:inline 2xl:inline">
                {values?.method?.label}
              </span>
              <span
                onClick={() => onOpenEditHandler()}
                className="text-[#007AFF] cursor-pointer"
              >
                Edit
              </span>
            </div>
            <span className="sm:hidden text-lg">{values?.method?.label}</span>
            {formHandler.watch('method')?.type == "one_way" && (
              <>
                <div className="flex items-center tracking-wide xs:tracking-tight md:tracking-tight font-normal text-lg md:justify-between md:text-base md:font-medium lg:justify-between lg:text-base lg:font-medium xl:justify-between xl:text-base xl:font-medium">
                  <div>{values?.booking_details?.from}</div>
                  <div className="xs:basis-16 md:basis-0 lg:basis-0 xl:basis-0">
                    →
                  </div>
                  <div>{values?.booking_details?.to}</div>
                </div>
                <div className="flex w-fit items-center gap-4 font-normal text-lg md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                  <span>Estimated arrival in {duration}</span>
                  <span>•</span>
                  <span>{distance}</span>
                </div>
              </>
            )}
            {formHandler.watch('method')?.type == "by_the_hour" && (
              <>
                <div className="flex w-fit items-center gap-4 font-normal text-lg md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                  <div> Pickup Location :</div>
                  <div>{values?.booking_details?.from}</div>
                </div>
                <div className="flex w-fit items-center gap-4 font-normal text-lg md:text-base md:font-medium lg:text-base lg:font-medium xl:text-base xl:font-medium">
                  <div>Booking Duration :</div>
                  <div>{values?.booking_details?.duration} hours</div>
                </div>
              </>
            )}
          </div>
        ))}
      <div>{activeContent}</div>
    </div>
  );
};

export default Tabs;

const Tab = ({ title, isActive, onClick, index, tabsData, url }) => {
  const activeClass = isActive ? "text-gray-950" : "";

  return (
    <>
      <button
        className={`tab text-gray-400 py-2 text-lg font-medium ${activeClass}`}
        onClick={onClick}
      >
        <div className="flex items-center gap-2 mr-2">
          <img src={url} alt={title} className="size-4" />
          <p>{title}</p>
        </div>
      </button>
      {index < tabsData?.length - 1 && (
        <span className="sm:w-16 md:w-6 border border-gray-400 h-0"></span>
      )}
    </>
  );
};
