import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm.js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export function Stripe({ formHandler, prices }) {
  let price = Number((prices.totalPrice * 100).toFixed(0));
  const options = {
    mode: "payment",
    amount: price,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
    paymentMethodTypes: ["card"],
    automatic_payment_methods: { enabled: false },
    //remove // Secure, 1-click checkout with Link
    // Securely pay with your saved info, or create a Link account for faster checkout next time.
    payment_method_options: {
      card: {
        request_three_d_secure: "any",
      },
    },
  };

  stripePromise.then((stripe) => {});

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm amount={price} prices={prices} formHandler={formHandler} />
    </Elements>
  );
}
