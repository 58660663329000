import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController, Controller } from "react-hook-form";
import _ from "lodash";
export const DatePickerField = React.forwardRef((props, ref) => {
  const [focusState, setFocusState] = useState(false);
  const {
    title,
    style,
    type,
    errors,
    defaultValue,
    isDisabled = false,
    dateTime = true,
    customStyles,
    onChange,
    maxDate,
    isHighLight = false,
    ellipses = false,
    showTitle = true,
    showTimeSelectOnly = false,
    ...others
  } = props;
  const { field, fieldState } = useController(props);
  let err = _.get(errors, props.name);
  return (
    <>
      <div className="flex flex-col">
        {showTitle && <div
          className={`pointer-events-none font-normal mb-2 ${
            props?.rules && err ? "text-red-600" : "text-gray-600"
          }`}
        >
          {title}
          {props?.rules?.required?.value === true && (
            <span className="text-red-500 p-2">*</span>
          )}
        </div>}
        <div
          className={`${
            props?.height ? props.height : !showTitle ? "h-[35px]" : "h-[45px]"
          }  relative w-full ${
            props?.color ? props.color : `${showTitle && ' bg-[#ECECEC] '} outlineStyle rounded`
          } ${
            props?.textColor ? props.textColor : ""
          } 
          ${
            props?.rules && err
              ? "focus-within:border-red-600 border-red-600"
              : "focus-within:border-teal-c-900"
          }`}
        >
          <Controller
            autoFocus={false}
            name={props?.name}
            control={props?.control}
            rules={props?.rules}
            defaultValue={defaultValue}
            render={({ field: { ref } }) => {
              function validDate(x) {
                let y = new Date(x);
                return y instanceof Date && !isNaN(y);
              }
              let updateDate = new Date(field?.value);
              if (!validDate(field?.value)) {
                updateDate = "";
              }
              return (
                <DatePicker
                  {...field}
                  ref={(elem) => {
                    elem && ref(elem.input);
                  }}
                  autoComplete="off"
                  autoFocus={false}
                  showTimeInput={dateTime}
                  showTimeSelectOnly={props.showTimeSelectOnly}
                  onChange={(e) => {
                    if (e != null) {
                      field.onChange(e);
                    } else {
                      field.onChange("");
                    }
                    if (onChange) {
                      onChange(e, props?.name);
                    }
                  }}
                  selected={updateDate}
                  //new change for handling reset (start)
                  // value={defaultValue}
                  //(end)
                  onFocus={() => setFocusState(true)}
                  onBlur={() => {
                    field.onBlur();
                    setFocusState(false);
                  }}
                  className={`block ${showTitle ? 'py-2' :'py-1'} w-full px-3 appearance-none relative focus:outline-none bg-transparent focus:ring-0 font-normal text-base text-black-c-400 ${
                    isDisabled ? "opacity-50" : ""
                  }                     ${
                    isHighLight && " focus:bg-highLight   "
                  }                `}
                  placeholder={props.placeholder ? props.placeholder : ""}
                  disabled={isDisabled ? isDisabled : false}
                  maxDate={props?.maxDate}
                  minDate={props?.minDate}
                  {...others}
                />
              );
            }}
          />
        {props.rules && err && (
          <p className=" text-xs text-red-600 h-3 mt-2" id="email-error">
            {props.rules && err && props.rules && err.message}
          </p>
        )}
        </div>
      </div>
    </>
  );
});