import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import { Screens } from "./details";
import { FaAngleLeft } from "react-icons/fa";
import { getPrice } from "../../../components/common.lib";
import axios from 'axios';
import TripDetail from '../../../assets/Booking_img/Frametrip.png'
import SelectVehicle from '../../../assets/Booking_img/Framevehicle.png'
import ConfirmDetails from '../../../assets/Booking_img/confirmdetails.png'
import { useNavigate } from "react-router-dom";

function Booking({ formHandler, booking }) {
  const [activeTab, setActiveTab] = useState("trip-details");
  const values = formHandler.getValues();
  const prices = getPrice({...formHandler.getValues(), method: formHandler.watch('method')?.type})
  const [vehicleError, setVehicleError] = useState(false);
  const navigate = useNavigate();


  const tabsData = [
    {
      id: "trip-details",
      title: "Trip Details",
      url: TripDetail,
      content: <Screens.TripDetails formHandler={formHandler} />,
    },
    {
      id: "select-vehicle",
      title: "Select Vehicle",
      url: SelectVehicle,
      content: (
        <Screens.Vehicle
          formHandler={formHandler}
          vehicleError={vehicleError}
          setVehicleError={setVehicleError}
        />
      ),
    },
    {
      id: "confirm-details",
      title: "Confirm Details",
      url: ConfirmDetails,
      content: (
        <Screens.ConfirmationDetails
          formHandler={formHandler}
          method={values?.method}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      id: "payment-details",
      title: "Payment Details",
      url: ConfirmDetails,
      content: <Screens.Stripe formHandler={formHandler} prices={prices} />,
    },
  ];

  const makePayment = (token) => {
    const body = {
      token,
      product: {
        name: "ChauffeurService",
        price: prices.totalPrice,
        description: "Payment",
      },
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment`, {
      headers,
      body: JSON.stringify(body),
    }).then((response) => {
      console.log(response);
      const { status } = response;
      console.log("STATUS", status);
    });
  };

  useEffect(() => {
    document.title = "ChauffeurService | Booking";
    // window.scrollTo(0, 0);
  }, [tabsData]);

  const backHandler = () => {
    let findIndex = tabsData.findIndex((tab) => tab.id === activeTab);
    if (findIndex > 0) {
      setActiveTab(tabsData[findIndex - 1].id);
    }
    if (activeTab == "trip-details") {
      navigate("/");
    }
      
  };

  const onSubmit = (data) => {
    if (activeTab == 'select-vehicle' && !formHandler.getValues()?.trip_details?.vehicle_type) {
      setVehicleError(true);
      return;
    }
    let findIndex = tabsData.findIndex((tab) => tab.id === activeTab);
    if (findIndex < tabsData.length - 1) {
      setActiveTab(tabsData[findIndex + 1].id);
    }

  };

  return (
    <div className="pb-16">
      <h1 className="h-40 pt-20 text-center bg-black text-baseColor font-bold text-2xl">
        ChauffeurService {">"} Booking
      </h1>
      <div className="container mx-auto px-4 sm:px-10">
        <h2
          className="h-5 font-semibold text-lg text-[#007AFF] cursor-pointer mt-5 flex items-center gap-1 w-fit"
          onClick={backHandler}
        >
          {" "}
          <FaAngleLeft /> Back{" "} {activeTab === 'trip-details' && 'to Home'}
        </h2>
        <Tabs
          tabsData={tabsData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          formHandler={formHandler}
          method={values?.method}
          booking={booking}
        />
          {activeTab !== 'payment-details' && <button
            className="bg-black text-baseColor text-lg font-semibold py-2 px-4 rounded-lg w-full mt-4 md:7 lg:mt-8 xl:mt-9"
            type="submit"
            onClick={formHandler.handleSubmit(onSubmit)}
          >
            Next
          </button>}
      </div>
    </div>
  );
}

export default Booking;
