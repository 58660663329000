import React from "react";

function ErrorPage(props) {
  // make an advanced error page and Not found page with Tailwind CSS
  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-9xl font-bold text-gray-800">
          {props?.code || 404}
        </h1>
        <h2 className="text-3xl font-bold text-gray-800">
          {props?.Msg || "Page Not Found"}
        </h2>
      </div>
    </div>
  );
}

export default ErrorPage;
