import React, { useState } from "react";
import { useController, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import _ from "lodash";
export const MaskField = React.forwardRef((props, ref) => {
     const { field, fieldState } = useController(props);
     const [focusState, setFocusState] = useState(false);
     const {
          title,
          style,
          errors,
          defaultValue,
          customStyle,
          onChange,
          mask = "99/99/9999",
          isDisabled = false,
          ...others
     } = props;
     let err = _.get(errors, props.name);
     return (
          <div className="w-full">
               <div
                    className={`pointer-events-none font-medium text-base mb-2 ${
                         props?.rules && err ? "text-red-600" : "text-gray-600"
                    }`}
               >
                    {title}
                    {props?.rules?.required?.value === true && <span className="text-red-500 p-2">*</span>}
               </div>
               <div
                    className={`${
                         props?.height ? props.height : "h-[45px]"
                    } px-3 py-2 relative w-full bg-[#ECECEC] focus-within:bg-[#ECECEC] outlineStyle rounded ${
                         props?.rules && err
                              ? "focus-within:border-red-600 border-red-600"
                              : "focus-within:border-teal-c-900"
                    }`}
               >
                    <Controller
                         name={props?.name}
                         control={props?.control}
                         rules={props?.rules}
                         defaultValue={defaultValue}
                         render={({ field: { ref } }) => (
                              <InputMask
                                   {...props}
                                   {...field}
                                   ref={(elem) => {
                                        elem && ref(elem.input);
                                   }}
                                   mask={mask}
                                   onBlurCapture={() => {
                                        field.onBlur();
                                        setFocusState(false);
                                   }}
                                   onFocus={() => setFocusState(true)}
                                   onChange={(e) => {
                                        field.onChange(e);
                                        if (props.onChange) {
                                             props.onChange(e, props?.name);
                                        }
                                   }}
                                   disabled={isDisabled ? isDisabled : false}
                                   placeholder={props.placeholder ? props.placeholder : ""}
                                   value={field.value}
                                   className={`block w-full appearance-none focus:outline-none bg-transparent focus:ring-0 text-lg font-normal text-black ${
                                        isDisabled && "bg-gray-50 cursor-not-allowed"
                                   }`}
                                   {...others}
                              />
                         )}
                    />
               </div>
               {props.rules && err && (
                    <p className=" text-xs text-red-600 h-3" id="email-error">
                         {props.rules && err && props.rules && err?.message}
                    </p>
               )}
               {/*  */}
          </div>
     );
});
