import data from './data'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Fragment, useState } from 'react';



const Faqs = () => {
  const [questions, setQuestions] = useState(data);
  const [showInfo, setShowInfo] = useState([]);

  const onOpenHandler = (id) => {
    if (showInfo.includes(id)) {
      setShowInfo(showInfo.filter((info) => info !== id));
    } else {
      setShowInfo([...showInfo, id]);
    }
  }

  return (
    <main className="p-[6.7%] sm:p-[5%] lg:p-[8%]">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center">
        <section className=" space-y-6">
          <h2 className="text-2xl lg:text-[1.2rem] xl:text-2xl ">
            FAQs about Limousine service - People also ask!
          </h2>
          {questions?.map((question) => {
            const { id, title, info } = question;
            return (
              <Fragment key={id}>
                <article>
                  <header
                    key={id}
                    className="flex w-full items-start justify-end text-left text-gray-900 text-lg"
                  >
                    <h2 className="flex-1">{title}</h2>
                    <button
                      className="ml-10 h-7"
                      onClick={() => onOpenHandler(id)}
                    >
                      {showInfo?.includes(id) ? (
                        <AiOutlineMinus className="size-5" />
                      ) : (
                        <AiOutlinePlus className="size-5" />
                      )}
                    </button>
                  </header>
                  {showInfo?.includes(id) && <p className='mt-3 font-medium'>{info}</p>}
                </article>
              </Fragment>
            );
          })}
        </section>
        <div className="p-6 rounded-2xl border bg-opacity-20 mt-10 lg:w-96 lg:h-52 justify-self-center">
          <div className="text-center space-y-4">
            <div className='space-y-2'>
              <h2 className="text-2xl">
                Do you have more questions?
              </h2>
              <p className="opacity-80 text-lg">
                That’s ok. Just drop a message and we will get back to you ASAP.
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button
                type="submit"
                className="w-full rounded-xl px-8 py-3 text-center font-bold text-base bg-black text-baseColor "
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Faqs;

