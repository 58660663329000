import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import moment from "moment/moment";
import { sendEmail } from "../../../../../components/common.lib";

export default function CheckoutForm({ amount, formHandler, prices }) {
  const stripe = useStripe();
  const elements = useElements();
  const bookingData = formHandler.getValues();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/create-intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
        body: JSON.stringify({
          amount,
          billing_details: { ...formHandler.getValues() },
          metadata: {
            prices: prices,
            ...bookingData,
            booking_details: {
              ...bookingData?.booking_details,
              date: moment(bookingData?.booking_details?.date, "MM/DD/YYYY").format(
                "dddd, MMM DD, YYYY"
              ),
              time: moment(bookingData?.booking_details?.time, "HH:mm").format(
                "hh:mm A"
              ),
            },
            trip_details: {
              ...bookingData?.trip_details,
              vehicle_type: {
                ...JSON.parse(bookingData?.trip_details?.vehicle_type),
                icon: "",
              },
            },
          },
        }),
      }
    );

    const { client_secret: clientSecret } = await res.json();

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      paymentMethod: {
        type: "card",
        card: elements.getElement(PaymentElement),
      },

      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONTEND_URL}/confirm`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // let emailPostData = {
      //   name: `${bookingData.passenger.first_name} ${bookingData.passenger.last_name}`,
      //   email: bookingData.passenger.email,
      //   phone: bookingData.passenger.phone_number,
      //   pickup: bookingData.booking_details.from,
      //   dropoff: bookingData.booking_details.to,
      //   date: moment(bookingData.booking_details.date).format(
      //     "DD/MM/YYYY - dddd"
      //   ),
      //   time: bookingData.booking_details.time,
      //   distance: bookingData.booking_details.distance,
      //   passengers: bookingData.trip_details.number_of_passengers,
      //   luggage: bookingData.trip_details.number_of_bags,
      //   baby_seat: bookingData.trip_details.baby_seat_wheel_chair?.join(", "),
      //   pet_seat: bookingData.trip_details.pet_seat,
      //   extra_stop: bookingData.trip_details.extra_stop,
      //   meet_greet: bookingData.trip_details.meet_greet_services,
      //   flight_number: bookingData.trip_details.flight_number,
      //   additional_info: bookingData.trip_details.additional_information,
      //   vehicle: JSON.parse(bookingData.trip_details.vehicle_type).label,
      //   amount: `$ ${(amount / 100).toFixed(2)}`,
      //   to: bookingData?.booking_details?.to,
      //   from: bookingData?.booking_details?.from,
      //   vehicle_price: `$ ${
      //     JSON.parse(bookingData.trip_details.vehicle_type).price
      //   }`,
      //   others: `$ ${
      //     (amount / 100).toFixed(2) -
      //     JSON.parse(bookingData.trip_details.vehicle_type).price
      //   }`,
      // };

      // sendEmail({...emailPostData, template_id: process.env.REACT_APP_EMAIL_TEMPLATE_ID});
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div>{errorMessage}</div>}
      <PaymentElement />
      <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-800 mb-2">
          Important Note
        </h3>
        <p className="text-gray-700 mb-2">
          We want to assure you that we do not store any <strong>payment details</strong>. The
          information you provide here is used solely for processing your <strong>payment</strong>.
          Your <strong>privacy</strong> and <strong>security</strong> are our top priorities.
        </p>
        <h3 className="text-lg font-semibold text-gray-800 mb-2">
          Waiting Policy
        </h3>
        <p className="text-gray-700 mb-2">
          For airport flight arrivals, the driver will wait for up to{" "}
          <strong>1 hour</strong> after your flight arrives without any charge.
          After this hour, a fee of <strong>$1 per minute</strong> will be
          applied.
        </p>
        <p className="text-gray-700">
          For all other pickups, the driver will wait for{" "}
          <strong>15 minutes</strong> at no charge. After this initial waiting
          period, an additional fee of <strong>$1 per minute</strong> will be
          charged for the previous 15 minutes.
        </p>
      </div>
      <button
        className="bg-black text-baseColor text-lg font-semibold py-2 px-4 rounded-lg w-full mt-4 md:7 lg:mt-8 xl:mt-9"
        type="submit"
        disabled={!stripe || loading}
        // onClick={formHandler.handleSubmit(onSubmit)}
      >
        Pay ${(amount / 100).toFixed(2)}
      </button>
    </form>
  );
}
