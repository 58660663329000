import React, { useState } from "react";
import { sendEmail } from "./common.lib";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      name: '',
      email: '',
      message: ''
    })
    sendEmail(
      {
        from_name: formData?.name,
        message: formData?.message,
        from_email: formData?.email,
        template_id: process.env.REACT_APP_EMAIL_CONTACT_TEMPLATE_ID
      }
    )
  };

  return (
    <div className="bg-cover bg-center bg-opacity-90 bg-man-image bg-fixed">
      <div className="text-white lg:max-w-[90%] xl:max-w-[87%] mx-auto py-11">
        <div className="flex items-center justify-center gap-4 mb-4">
          <div className="h-1 w-16 bg-white"></div>
          <h2 className="text-2xl font-medium">CONTACT</h2>
          <div className="h-1 w-16 bg-white"></div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pl-[6.7%] sm:px-[5%] lg:pl-[3%] xl:pl-[1.5%]">
          <div className="space-y-5 mt-2 w-[60%] md:w-full">
            <div>
              <h2 className="text-baseColor font-medium text-2xl mb-2">
                HEADQUARTER
              </h2>
              <p className="font-medium text-lg">
                146 Manley Ln, Milton, Ontario, L9T 5P1
              </p>
            </div>
            <div>
              <h2 className="text-baseColor font-medium text-2xl">EMAIL</h2>
              <p className="font-medium text-lg">info@aircanadalimo.com</p>
            </div>
            <div>
              <h2 className="text-baseColor font-medium text-2xl">
                Phone number
              </h2>
              <p className="font-medium text-lg">+1 647 617 1387</p>
            </div>
          </div>
          <div className="">
            <div className="space-y-2">
              <h2 className="text-baseColor font-medium text-2xl mb-2">
                Send us a message
              </h2>
              <p className="font-medium text-lg">
                We welcome all your inquiries.
              </p>
            </div>
            <form onSubmit={handleSubmit} className="mt-7 pr-[6.7%] ">
              <div className="space-y-5">
                <div className="">
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="name"
                      id="text"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      className="block text-lg font-semibold w-full rounded-xl border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      className="block text-lg font-semibold w-full rounded-xl border-1 px-4 text-gray-900 py-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      placeholder="How can I book a Private Airport pick-up"
                      value={formData.message}
                      onChange={handleChange}
                      className="block text-lg h-20 font-semibold w-full rounded-xl border-1 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>

                <div className="mt-3 flex justify-center">
                  <button
                    type="submit"
                    className="rounded-xl px-8 py-4 text-center font-bold text-base bg-black text-baseColor "
                  >
                    Send message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
