import React from "react";
import Select from "react-select";
import { Controller, useController } from "react-hook-form";
import _ from "lodash";

export const ReactSelectField = React.forwardRef((props, ref = null) => {
  const {
    isDisabled = false,
    title,
    style,
    errors,
    defaultValue,
    onChange,
    isMulti = false,
    capitalize = true,
    menuPlacement,
    isHighLight = false,
    ellipses,
    showMenuOnTop = false,
    maxMenuHeight,
    placeholderHeight = false,
    uniqueKey,
    showTitle = true,
    ...others
  } = props;
  const { field } = useController(props);
  let err = _.get(errors, props.name);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: !showTitle ? 35 : 45,
      minHeight: props.minHeight ? props.minHeight : 35,
      maxHeight: "100%",
      boxShadow: "none",
      border: "none",
      backgroundColor: showTitle ? `#ECECEC` : "bg-red-50",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 50,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  const getOptionLabel = (e) => {
    if (props.getOptionLabel) {
      return props.getOptionLabel(e);
    } else {
      return e[props.showLabel || "label"];
    }
  };

  return (
    <div>
      <div
        className={`relative bg-white text-gray-500 rounded text-left ${
          isDisabled && " cursor-not-allowed "
        }`}
      >
        {showTitle && (
          <div
            className={`pointer-events-none font-medium text-base mb-2 ${
              props?.rules && err ? "text-red-600" : "text-gray-600"
            }`}
          >
            {title}
            {props?.rules?.required?.value === true && (
              <span className="text-red-500 p-2">*</span>
            )}
          </div>
        )}
        <Controller
          name={props?.name}
          rules={props?.rules}
          control={props?.control}
          defaultValue={defaultValue}
          render={({ field: { onChange } }) => (
            <Select
              {...field}
              ref={field.ref}
              menuPortalTarget={showMenuOnTop && document.body}
              maxMenuHeight={maxMenuHeight}
              isLoading={props.isLoading || false}
              noOptionsMessage={() => null}
              getOptionLabel={getOptionLabel}
              styles={customStyles ? customStyles : style}
              isSearchable={true}
              options={props?.optionData}
              placeholder={props?.placeholder ? props?.placeholder : ""}
              menuPlacement={menuPlacement || "auto"}
              isClearable={true}
              onChange={(val, action) => {
                if (uniqueKey) {
                  if (isMulti) {
                    onChange(val.map((obj) => obj?.[uniqueKey]));
                  } else {
                    onChange(val?.[uniqueKey]);
                  }
                } else {
                  onChange(val);
                }
                if (props.onChange) {
                  props.onChange(val)
                }
              }}
              value={
                uniqueKey
                  ? field.value && isMulti
                    ? props?.optionData?.filter((obj) =>
                        field?.value.includes(obj?.[uniqueKey])
                      )
                    : field?.value
                    ? props?.optionData?.find(
                        (v, k) => v?.[uniqueKey] == field?.value
                      )
                    : null
                  : field.value
              }
              onBlur={(e) => {
                field.onBlur(e);
              }}
              isDisabled={isDisabled ? isDisabled : false}
              getOptionValue={(option) => option[props.showLabel || "label"]} // changes here!!!
              isOptionDisabled={(option) => option.disabled}
              isMulti={isMulti}
              {...others}
            />
          )}
        />
      </div>

      {props.rules && err && props.rules && err?.message ? (
        <p className=" text-xs text-red-600 h-3 absolute" id="email-error">
          {props.rules && err && props.rules && err?.message}
        </p>
      ) : (
        ""
      )}
    </div>
  );
});
