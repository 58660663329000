import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Confirmations from "./confirmations";
import BookingConfirmation from "./confirmations";
import ErrorPage from "./error";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sendEmail } from "../../components/common.lib";

function ConfirmationPage() {
  const [loading, setLoading] = React.useState(true);

  // const redirect_status = new URLSearchParams(window.location.search).get(
  //   "redirect_status"
  // );
  // get paymentIntentId from the URL
  const payment_intent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  const [paymentStatus, setPaymentStatus] = React.useState(null);

  const fetchPaymentConfirmation = async (paymentIntent) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/payment_confirmation?payment_intent=${paymentIntent}`
      );

      if (response?.data?.code === 0) {
        setPaymentStatus(response?.data);
        let metadata = cloneDeep(response?.data?.paymentIntent?.metadata);
        let emailPostData = {
          name: `${metadata?.first_name} ${metadata?.last_name}`,
          email: metadata?.email,
          phone: metadata?.phone_number,
          pickup: metadata?.from,
          dropoff: metadata?.to,
          date: moment(metadata?.date).format("DD/MM/YYYY - dddd"),
          time: metadata?.time,
          distance: metadata?.distance,
          passengers: metadata?.number_of_passengers,
          luggage: metadata?.number_of_bags,
          baby_seat: metadata?.baby_seat,
          pet_seat: metadata?.pet_seat,
          extra_stop: metadata?.extra_stop,
          meet_greet: metadata?.meet_greet_services,
          flight_number: metadata?.flight_number,
          additional_info: metadata?.additional_info,
          vehicle: metadata?.vehicle_type,
          amount: `$ ${(response?.data?.paymentIntent?.amount / 100).toFixed(0)}`,
          to: metadata?.to,
          from: metadata?.from,
          vehicle_price: `$ ${metadata?.vehicle_price}`,
          others: `$ ${metadata?.additional_price}`,
        };

        sendEmail({
          ...emailPostData,
          template_id: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        });
        const newUrl = new URL(window.location.href);
        newUrl.search = ''; // Remove all query parameters
        window.history.replaceState({}, document.title, newUrl.toString());
      }
    } catch (error) {
      setPaymentStatus(error?.response?.data);
    }
  };

  useEffect(() => {
    // make a request to the backend to confirm the payment
    const redirectStatus = new URLSearchParams(window.location.search).get("redirect_status");
    
    if (redirectStatus === "succeeded") {
      console.log(redirectStatus, 'new URLSearchParams(window.location.search)."redirect_status');
      fetchPaymentConfirmation(payment_intent);
    }
    setLoading(false);
  }, [payment_intent]); // Added payment_intent as a dependency to ensure useEffect runs only once

  if (loading) {
    return "Loading...";
  }

  
  if (paymentStatus?.code == 0) {
    return <BookingConfirmation paymentStatus={paymentStatus} />;
  }

  // if (
  //   paymentStatus === null ||
  //   paymentStatus === undefined ||
  //   paymentStatus === ""
  // ) {
  //   return <ErrorPage code={505} Msg="Something went wrong" />;
  // }

  if (paymentStatus?.code == -1) {
    return <Navigate to="/" />;
  }


  // return (
  //   <div>
  //     {loading ? 'Loading...' :<>
  //     {paymentStatus === null ||
  //     paymentStatus === undefined ||
  //     paymentStatus === "" ? (
  //       <ErrorPage code={505} Msg="Something went wrong" />
  //     ) : paymentStatus.code === -1 ? (
  //       <Navigate to="/" />
  //     ) : paymentStatus.code === 0 ? (
  //       <BookingConfirmation />
  //     ) : null}</>}
  //   </div>
  // );
}

export default ConfirmationPage;
